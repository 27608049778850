'use strict'
import Config from '@/config.json'

// NOTE
// Les states avec un _ doivent être accédés via des getters
// Les mutations avec un _ doivent être appelées uniquement depuis des actions

export default {
  state: {
    mapLoading: true,
    mapRef: {},           // Référence vers l'objet OpenLayers
    backgroundLayer: {    // Fond de carte du viewer
      name: Config.mapConfiguration.defaultBackgroundLayer,
      opacity: 1
    },
    topographyLayer: {},   // Topographie
    mapLegend: null,
    drawInteraction: null,
    currentGeom: null
  },
  getters: {
    mapView (state) {
      return state.mapRef.getView()
    }
  },
  mutations: {
    _setLoadingState (state, loading) {
      state.mapLoading = loading
    },
    _setMapRef (state, mapRef) {
      state.mapRef = mapRef
    },
    setTopographyLayer (state, topographyLayer) {
      state.topographyLayer = topographyLayer
    },
    setMapLegend (state, mapLegend) {
      state.mapLegend = mapLegend
    },
    setCurrentGeom (state, geom) {
      state.currentGeom = geom
    },
    setDrawInteraction (state, drawInteraction) {
      state.drawInteraction = drawInteraction
    },
    setBackgroundLayerName (state, name) {
      state.backgroundLayer.name = name
    },
    setBackgroundLayerOpacity (state, opacity) {
      state.backgroundLayer.opacity = opacity
    }
  },
  actions: {
    // Récupération des groupes de sources de données via l'API
    initMap ({ commit }, mapRef) {
      commit('_setLoadingState', true)
      commit('_setMapRef', mapRef)
      commit('_setLoadingState', false)
    },
  }
}
