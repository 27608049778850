import Vue from 'vue'
import VueRouter from 'vue-router'

import Map from '@/views/map/map.vue'
import SplashScreen from '@/components/common/splash-screen'

import MapReservaqua from '@/views/map/map_reservaqua.vue'
import SplashScreenReservaqua from '@/components/common/splash-screen-reservaqua'

import NoAccess from '@/views/common/no-access.vue'

import Config from '../config.json'

Vue.use(VueRouter)

let components = {}


switch (Config.appName) {
  case 'RESERVAQUA':
    components = {
      default: MapReservaqua,
      splashScreen: SplashScreenReservaqua
    }
    break
  default:
  case 'STRATES-VIEWER':
    components = {
      default: Map,
      splashScreen: SplashScreen
    }
    break
}

const routes = [
  {
    path: '/',
    name: 'home',
    components
  },
  {
    path: '/403',
    name: 'no-access',
    component: NoAccess
  }
]

const router = new VueRouter({
  mode: 'history',
  base: Config.frontendBaseUrl,
  routes
})

export default router
