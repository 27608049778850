import Axios from 'axios'
import FetcherHelper from '@/utils/fetcherHelper'
import Config from '@/config'

export default class SourceProxy {
  static get baseUrl () {
    return `${Config.apiBaseUrl}/sources`
  }

  static getGroups () {
    return new Promise((resolve, reject) => {
      Axios.get(`${SourceProxy.baseUrl}/group`, FetcherHelper.getHeaders())
        .then((response) => {
          resolve(response.data)
        }).catch(reject)
    })
  }

  static getSource (sourceId) {
    return new Promise((resolve, reject) => {
      Axios.get(`${SourceProxy.baseUrl}/${sourceId}`, FetcherHelper.getHeaders())
        .then((response) => {
          resolve(response.data)
        }).catch(reject)
    })
  }
}