<i18n>
  {
    "fr": {
      "add-group-label": "Ajouter la carte",
      "show-detail-group": "Afficher le contenu de la carte",
      "hide-detail-group": "Masquer le contenu de la carte"
    }
  }
</i18n>

<template>
  <div class="group md-elevation-2">
    <div class="header-group">
      <div class="module-label" :style="{background: sourceGroup.moduleMetadata.color}"></div>
      <div class="expand-arrow" @click="toggleExpend()">
        <md-icon v-if="open">keyboard_arrow_up</md-icon>
        <md-icon v-if="!open">keyboard_arrow_down</md-icon>
        <md-tooltip>{{ $t(open ? 'hide-detail-group' : 'show-detail-group') }}</md-tooltip>
      </div>
      <div class="group-name" @click="toggleExpend()">
        <div class="md-subtitle">
          {{sourceGroup.themeMetadata.name}}
        </div>
        <div class="md-title">
          {{ sourceGroup.groupMetadata.name }}
        </div>
      </div>
      <div class="button-container">
        <md-button class="md-icon-button" @click="addSourceGroup(sourceGroup)">
          <md-icon>add_circle</md-icon>
          <md-tooltip md-direction="bottom">{{ $t('add-group-label') }}</md-tooltip>
        </md-button>
      </div>
    </div>
    <div v-if="open" class="layer-container">
      <SourceCard
        v-for="sourceLayer in sourceGroup.sources"
        :key="`source_${sourceGroup.id}_${sourceLayer.id}`"
        :class="['group-source', {'inUse': sourceLayer.inUse}]"
        :sourceLayer="sourceLayer"
        :sourceGroup="sourceGroup"
      >
      </SourceCard>
    </div>
  </div>
</template>

<script>

import LayerStoreMixin from '@/mixins/store/layer'
import SourceCard from '@/components/layerNavigation/source/sourceCard'

export default {
  name: 'SourceGroup',
  components: {
    SourceCard
  },
  mixins: [
    LayerStoreMixin
  ],
  props: {
    sourceGroup: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return  {
      open: false
    }
  },
  methods: {
    toggleExpend () {
      this.open = !this.open
    },
    handleError (e) {
      console.log(e)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>