'use strict'
import Vue from 'vue'
import Vuex from 'vuex'

import common from '@/store/common.js'
import layer from '@/store/layer.js'
import map from '@/store/map.js'
import tool from '@/store/tool.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    common,
    layer,
    map,
    tool
  }
})
