'use strict'

// NOTE
// Les states avec un _ doivent être accédés via des getters
// Les mutations avec un _ doivent être appelées uniquement depuis des actions

import availableTools from '@/components/tool/availableTools.json'

let getDefaultToolConfiguration = (key) => {
  return availableTools.find((tc) => tc.key === key)
}

export default {
  state: {
    tools: [],        // Outils actifs sur le viewer
  },
  mutations: {
    setToolsForLayer (state, layer) {
      layer.webviewConfig.availableTools.forEach((tool) => {
        let defaultConfig = getDefaultToolConfiguration(tool.key)
        let existingTool = state.tools.find((t) => t.key === tool.key)
        if (typeof existingTool === 'undefined') {
          tool.params = {
            ...defaultConfig.params,
            ...tool.params
          }
          state.tools.push({
            ...defaultConfig,
            ...tool,
            vueKey: `${tool.key}_${layer.sourceId}`
          })
        }
      })
    },
    emptyTools (state) {
      state.tools = []
    }
  },
  actions: {

  }
}
