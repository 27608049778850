<i18n>
{
  "fr": {
    "opacity": "Opacité",
    "time": "Sélection temporelle",
    "legend-label": "Légende",
    "opacity-label": "Opacité",
    "delete-label": "Supprimer",
    "layer-list-label": "Liste des couches",
    "show-hide-label": "Afficher / masquer"
  },
  "de": {

  }
}
</i18n>

<template>
  <div
    :class="['layer-item md-elevation-2', {'disabled': disabled}]"
  >
    <div class="header" @click="toggleSelected()">
      <div class="icon">
        <img class="geom-type-icon" v-if="layer.geomType === 'raster'" src="@/assets/images/geom/raster-icon.png">
        <img class="geom-type-icon" v-if="layer.geomType === 'point'" src="@/assets/images/geom/point-icon.png">
        <img class="geom-type-icon" v-if="layer.geomType === 'line'" src="@/assets/images/geom/line-icon.png">
        <img class="geom-type-icon" v-if="layer.geomType === 'polygon'" src="@/assets/images/geom/polygon-icon.png">
      </div>
      <div class="md-title">
        {{layer.metadata.name}}
      </div>
      <div class="button-container">
        <md-button :class="['md-icon-button md-dense', {'md-primary': showOnMap}]" @click.stop="clickShowHide">
          <md-icon>remove_red_eye</md-icon>
          <md-tooltip md-direction="bottom">{{ $t('show-hide-label') }}</md-tooltip>
        </md-button>
      </div>
    </div>
    <div class="actions" v-on:click.stop>
      <div class="opacity-container action">
        <div class="opacity-header md-caption" @click="showOpacity = !showOpacity">
          <md-icon v-if="showOpacity">keyboard_arrow_up</md-icon>
          <md-icon v-if="!showOpacity">keyboard_arrow_down</md-icon>
          {{$t('opacity')}}
        </div>
        <div v-if="showOpacity" class="opacity-content action-content">
          <vue-slider
            v-model="manualOpacity"
            :min="0"
            :max="1"
            :interval="0.1"
          >
          </vue-slider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LayerStoreMixin from '@/mixins/store/layer'
import _ from 'lodash'
import Config from '@/config.json'

export default {
  name: 'LayerCard',
  mixins: [
    LayerStoreMixin
  ],
  props: {
    layer: {
      type: Object,
      default: () => { return {} }
    },
    layerGroup: {
      type: Object,
      default: () => { return {} }
    },
    disabled: {
      type: Boolean,
      default: () => { return {} }
    }
  },
  data () {
    return {
      showOpacity: false,
      showLegend: false,
      manualOpacity: _.clone(this.layer.webviewConfig.opacity),
      showOnMap: _.clone(this.layer.webviewConfig.showOnMap)
    }
  },
  computed: {
    isSelected () {
      return this.selectedLayers.includes(this.layer)
    },
    hasNoFocus () {
      return this.selectedLayers.length > 0 && !this.isSelected
    },
    opacity () {
      if (this.hasNoFocus) return Config.layerConfiguration.opacityWhenNotFocus
      return this.manualOpacity
    }
  },
  watch: {
    opacity (val) {
      this.layer.setOpacity(val)
    },
    isSelected (val) {
      if (val) this.manualOpacity = 1
      if (val && !this.showOnMap) this.showOnMap = true
    },
    showOnMap (val) {
      this.layer.setVisible(val)
    }
  },
  methods: {
    toggleOpacity () {
      this.showOpacity = !this.showOpacity
    },
    clickShowHide () {
      this.showOnMap = !this.showOnMap
    },
    toggleSelected () {
      this.toggleSelectedLayer(this.layer)
      if (this.isSelected) this.setLayerGroupInfo(this.layerGroup)
    }
  }
}
</script>

<style lang="scss">
  .layer-item {

    cursor: hand;
    background-color: white;
    padding: 7px;
    margin-top: 7px;
    margin-bottom: 7px;
    margin-left: 5px;
    margin-right: 5px;

    &.disabled {
      filter: saturate(0.2);
    }

    .actions {
      cursor: pointer;

      .action-content {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }

    &.selected {
      background-color: #184aa0;
      color: white;

      .action-content {
        color: black;
      }

      .delete-button .md-icon, .md-caption{
        color: white;
      }
    }

    .header {
      display: grid;
      grid-template-areas: 'icon title button';
      grid-template-columns: 20px auto auto;

      .icon {
        grid-area: icon;
      }

      .geom-type-icon {
        margin-left: 3px;
        margin-top: 7px;
      }

      .md-caption.time-info {
        display: block;
        margin-bottom: -10px;
      }

      .md-title {
        grid-area: title;
        margin-top: 5px;
        margin-left: 7px;
        font-size: 14px;
        line-height: 20px;
      }

      .button-container {
        grid-area: button;
        margin-left: auto;
      }

      .md-button {
        margin-right: 0px;
      }
    }

    .opacity-content {
      padding-left: 10px;
      padding-right: 10px;
    }

    .time-player-icon {
      width: 18px;
      margin-left: -2px;
      float: left;

      .md-icon {
        font-size: 17px !important;
      }
    }

    .time-slider {
      width: calc(100% - 13px) !important;
      margin-left: 13px;
      padding-top: 2px;
    }

    .time-content {
      padding: 7px;
      background-color: #eff1ed;
    }

    .layer-list-container {

      margin-top: 5px;
      .layer-list-content {

        padding: 7px;
        background-color: #eff1ed;

        .layer-group-item {

          margin: 0;
          margin-top: 5px;
          margin-left: 5px;

          &.selected {
            font-weight: 600
          }

        }
      }
    }

  }
</style>
