<template>
  <div class="tool-buttons">
    <component
      v-for="interactionButton in interactionButtons"
      :key="interactionButton.key"
      :is="interactionButton.componentName"
    />
  </div>
</template>

<script>

import InteractionMixin from '@/mixins/map/interaction'

import Point from '@/components/interaction/point'

export default {
  name: 'InteractionPanel',
  mixins: [
    InteractionMixin
  ],
  components: {
    Point
  }
}

</script>

<style lang="scss" scoped>

</style>
