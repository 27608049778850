import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'
import GeoJSON from 'ol/format/GeoJSON'
import { Style, Stroke } from 'ol/style'

export default class LineLayer extends VectorLayer {
  constructor (geoJson, style) {
    super({
      source: new VectorSource({
        features: new GeoJSON().readFeatures(geoJson)
      }),
      style: new Style({
        stroke: new Stroke({
          color: style.color,
          width: style.width
        })
      }),
      opacity: style.opacity
    })

    return this
  }
}
