'use strict'
import GeoJSON from 'ol/format/GeoJSON'
import { get as getProjection } from 'ol/proj'

const GeoJson = new GeoJSON({
  dataProjection: getProjection('epsg:2056'),
  featureProject: getProjection('epsg:2056')
})

export default GeoJson
