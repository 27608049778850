import Toolbox from '@/utils/toolbox'
import TileLayer from 'ol/layer/Tile'
import WMTS from 'ol/source/WMTS'
import WMTSTileGrid from 'ol/tilegrid/WMTS'
import { getTopLeft } from 'ol/extent'
import TileWMS from 'ol/source/TileWMS'

import { get as getProj } from 'ol/proj'
import EPSG from '@/utils/epsg'

import Axios from 'axios'
import GeomToolbox from '@/utils/geomToolbox'

export default class WmtsLayer extends TileLayer {
  static get serviceType () {
    return 'WMTS'
  }

  constructor (source) {
    const layerProjection = new EPSG(getProj(source.projection))

    super({
      source: new WMTS({
        url: source.url,
        layer: source.geoserverConfig.sourceName,
        matrixSet: source.projection,
        format: source.format,
        projection: layerProjection.projection,
        transition: 100,
        tileGrid: new WMTSTileGrid({
          extent: layerProjection.extent,
          origin: getTopLeft(layerProjection.extent),
          resolutions: layerProjection.resolutions,
          matrixIds: layerProjection.matrixIds
        })
      }),
      opacity: Toolbox.getOrDefault(source.webviewConfig, 'opacity', 1)
    })

    // Valeurs par défaut

    this.sourceId = source.id
    this.metadata = source.metadata
    this.geomType = source.geomType

    this.geoserverConfig = source.geoserverConfig

    this.webviewConfig = source.webviewConfig
    this.webviewConfig.selected = false
    this.webviewConfig.showOnMap = Toolbox.getOrDefault(source.webviewConfig, 'showOnMap', true)
    this.webviewConfig.opacity = Toolbox.getOrDefault(source.webviewConfig, 'opacity', 1)

    return this
  }

  getProjectionCode () {
    return this.getSource().getProjection().getCode()
  }

  getFeaturesFromCoordinates (mapRef, geom, params = {}, projection) {
    return new Promise((resolve, reject) => {
      params.INFO_FORMAT = Toolbox.getOrDefault(params, 'INFO_FORMAT', 'application/json')
      this.geoserverConfig.getFeature.params.forEach((param) => {
        params[param.key] = param.value
      })

      // Le type de layer étant un WMTS, la configuration de la source doit préciser une URL pour pouvoir faire une query de type "GetFeatureUrl"
      const wmsSource = new TileWMS({
        url: this.geoserverConfig.getFeature.url,
        projection: this.getProjectionCode()
      })

      // projection correspond à celle utilisé dans la carte
      const geomToRequest = GeomToolbox.transform(geom, projection, this.getSource().getProjection().getCode())

      const url = wmsSource.getFeatureInfoUrl(
        geomToRequest.coordinates,
        mapRef.getView().getResolution(),
        this.getProjectionCode(),
        params
      )

      Axios.get(url).then((resp) => {
        if (typeof resp.data.features === 'undefined') return reject(resp.data)
        resolve(resp.data.features)
      }).catch(reject)
    })
  }
}
