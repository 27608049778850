<i18n>
{
  "fr": {
    "layerGroupInfo-title": "Information sur la carte"
  },
  "de": {

  }
}
</i18n>

<template>
  <div class="container tool" v-if="show">
    <div class="moduleName" :style="{'background-color': selectedModule.color}">
      <img :src="icon" class="icon">
      <h2>{{ selectedModule.name }}</h2>
    </div>
    <p class="desc" v-html="selectedModule.desc"></p>
  </div>
</template>

<script>

import LayerStoreMixin from '@/mixins/store/layer'

import eauSurface from '@/assets/icons/eauSurface.png'
import eauSout from '@/assets/icons/eauSout.png'
import eauMeteoriques from '@/assets/icons/eauMeteoriques.png'
import gestionRessource from '@/assets/icons/gestionRessource.png'

export default {
  name: 'layerGroupHeaderDescription',
  mixins: [
    LayerStoreMixin
  ],
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    icon () {
      switch (this.selectedModule.icon) {
        case 'eauSurface':
          return eauSurface
        case 'eauSout':
          return eauSout
        case 'eauMeteoriques':
          return eauMeteoriques
        case 'gestionRessource':
          return gestionRessource
        default:
          return ''
      }
    }
  }
}

</script>

<style lang="scss" scoped>
  .moduleName {
    color: white;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    display: flex;

    .icon {
      width: 30px;
      height: 30px;
      margin-top: 9px;
      margin-right: 10px;
      margin-left: 10px;
    }

    h2 {
      font-size: 18px;
    }
  }

  .desc {
    text-align: justify;
  }

  .themeName {
    margin-top: 10px !important;
  }

  p {
    margin-bottom: 5px
  }

  .layerName {
    font-weight: 600;
  }
</style>
