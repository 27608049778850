'use strict'

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState({
      layerLoading: state => state.layer.layerLoading,
      layerGroups: state => state.layer.layerGroups,
      sourceGroups: state => state.layer.sourceGroups,
      selectedLayers: state => state.layer.selectedLayers,
      layerGroupInfo: state => state.layer.layerGroupInfo,
      selectedModule: state => state.layer.selectedModule,
      showLayerList: state => state.layer.showLayerList,
      showSourceList: state => state.layer.showSourceList
    }),
    ...mapGetters([
      'availableSourceGroups',
      'availableModules',
      'currentLayers',
      'showGroupList',
      'showModuleList',
      'filtredSourceGroups'
    ])
  },
  methods: {
    ...mapMutations([
      'toggleSelectedLayer',
      'addOrUpdateSourceGroup',
      'addOrUpdateLayerGroup',
      'setLayerGroupInfo',
      'removeLayerGroupInfo',
      'updateSelectedModule',
      'setShowSourceList',
      'setShowLayerList'
    ]),
    ...mapActions([
      'addSourceGroup',
      'addSourceLayer',
      'removeLayerGroup',
      'removeLayer'
    ]),
  }
}
