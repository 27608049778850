<i18n>
{
  "fr": {
    "opacity": "Opacité",
    "time": "Sélection temporelle",
    "legend-label": "Légende",
    "opacity-label": "Opacité",
    "delete-label": "Supprimer",
    "layer-list-label": "Liste des couches"
  },
  "de": {

  }
}
</i18n>

<template>
  <div
    class="source-item md-elevation-2"
  >
    <div class="header">
      <img class="geom-type-icon" v-if="sourceLayer.geomType === 'raster'" src="@/assets/images/geom/raster-icon.png">
      <img class="geom-type-icon" v-if="sourceLayer.geomType === 'point'" src="@/assets/images/geom/point-icon.png">
      <img class="geom-type-icon" v-if="sourceLayer.geomType === 'line'" src="@/assets/images/geom/line-icon.png">
      <img class="geom-type-icon" v-if="sourceLayer.geomType === 'polygon'" src="@/assets/images/geom/polygon-icon.png">
      <div class="md-title">
        {{sourceLayer.metadata.name}}
      </div>
      <!-- <div class="button-container">
        <md-button class="md-icon-button md-dense" @click="addSourceLayer({sourceGroup, sourceLayer})">
          <md-icon>add_circle</md-icon>
          <md-tooltip md-direction="bottom">{{ $t('add-layer-label') }}</md-tooltip>
        </md-button>
      </div> -->
    </div>

    <div class="description-container">
      <a href="#" class="description">{{sourceLayer.metadata.description | stripString(80)}}</a>
    </div>
  </div>
</template>

<script>

import LayerStoreMixin from '@/mixins/store/layer'

export default {
  name: 'SourceCard',
  mixins: [
    LayerStoreMixin
  ],
  props: {
    sourceLayer: {
      type: Object,
      default: () => { return {} }
    },
    sourceGroup: {
      type: Object,
      default: () => { return {} }
    }
  }
}
</script>

<style lang="scss">
  .source-item {

    padding: 10px;
    margin: 5px;

    cursor: pointer;
    background-color: white;

    .actions {
      cursor: pointer;
    }

    .header {
      display: grid;
      grid-template-areas: 'icon title button';
      grid-template-columns: 20px auto;

      .md-caption.time-info {
        display: block;
        margin-bottom: -10px;
      }

      .geom-type-icon {
        grid-area: icon;
      }

      .md-title {
        margin-left: 3px;
        font-size: 14px;
        line-height: 13px;
        grid-area: title;
      }

      .button-container {
        grid-area: button;
        margin-top: -7px;
      }

      .md-button {
        margin-right: 0px;
        margin-left: 19px;

        &.selected {
          background-color: red;
        }
      }
    }

    .description-container {
      a {
        color: grey !important;
      }
    }

  }
</style>
