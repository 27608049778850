'use strict'

// NOTE
// Les states avec un _ doivent être accédés via des getters
// Les mutations avec un _ doivent être appelées uniquement depuis des actions

export default {
  state: {
    isLeftPanelOpen: true,
    isRightPanelOpen: false,
    isBottomPanelOpen: false,
    showAboutPopup: false,
    showThemePopup: false
  },
  getters: {

  },
  mutations: {
    setLeftPanelState (state, value) {
      state.isLeftPanelOpen = value
    },
    setRightPanelState (state, value) {
      state.isRightPanelOpen = value
    },
    toggleLeftPanel (state) {
      state.isLeftPanelOpen = !state.isLeftPanelOpen
    },
    setShowAboutPopup (state, value) {
      state.showAboutPopup = value
    },
    setShowThemePopup (state, value) {
      state.showThemePopup = value
    }
  },
  actions: {

  }
}
