'use strict'

// NOTE Documentation https://api3.geo.admin.ch/

import { Tile as TileLayer } from 'ol/layer'
import EPSG from '@/utils/epsg'
import { get as getProj } from 'ol/proj'
import { WMTS as WMTSSource } from 'ol/source'
import WMTSTileGrid from 'ol/tilegrid/WMTS'
import { getTopLeft } from 'ol/extent'
// import { i18n } from '@/utils/i18n.js'

class SwisstopoSource extends WMTSSource {
  constructor (layer, layerProjection, attributions = [], timestamp = 'current') {
    super({
      url: `//wmts{0-9}.geo.admin.ch/1.0.0/{Layer}/default/${timestamp}/2056/{TileMatrix}/{TileCol}/{TileRow}.jpeg`,
      layer,
      projection: layerProjection.projection,
      tileGrid: new WMTSTileGrid({
        extent: layerProjection.extent,
        origin: getTopLeft(layerProjection.extent),
        resolutions: layerProjection.resolutions,
        matrixIds: layerProjection.matrixIds
      }),
      requestEncoding: 'REST',
      attributions
    })

    return this
  }
}

export default class SwisstopoLayer extends TileLayer {
  constructor (layer = 'ch.swisstopo.pixelkarte-farbe', timestamp = 'current') {
    let title
    const attributions = []
    // let attributions = [`©&nbsp;<a href="${i18n.t('swisstopoLayer.attributionsUrl')}" target="_blank" rel="noopener">geo.admin.ch</a>`]
    // let resolutionMin = 0
    const layerProjection = new EPSG(getProj('EPSG:2056'))

    switch (layer) {
      case 'ch.swisstopo.swissimage':
        // title = i18n.t('swisstopoLayer.swissimage.title')
        title = 'ch.swisstopo.swissimage'
        break

      case 'ch.swisstopo.pixelkarte-grau':
        // title = i18n.t('swisstopoLayer.pixelkarteGrau.title')
        title = 'ch.swisstopo.pixelkarte-grau'
        // resolutionMin = 0.25
        // attributions.push(`<a href="${i18n.t('swisstopoLayer.pixelkarteGrau.attribution.url')}" target="_blank">${i18n.t('swisstopoLayer.pixelkarteGrau.attribution.title')}</a>`)
        break

      case 'ch.swisstopo.pixelkarte-farbe':
        // title = i18n.t('swisstopoLayer.pixelkarteFarbe.title')
        title = 'ch.swisstopo.pixelkarte-farbe'
        // resolutionMin = 0.25
        // attributions.push(`<a href="${i18n.t('swisstopoLayer.pixelkarteFarbe.attribution.url')}" target="_blank">${i18n.t('swisstopoLayer.pixelkarteFarbe.attribution.title')}</a>`)
        break

      default:
        throw new Error(`layer Swisstopo inconnue: ${layer}`)
    }

    super({
      title,
      type: 'base',
      source: new SwisstopoSource(layer, layerProjection, attributions, timestamp),
      opacity: 1
    })

    return this
  }
}
