import GeoJSON from '@/utils/geoJson'
import * as turf from '@turf/turf'
import Config from '@/config.json'

export default class GeomToolbox {
  static samplePointsOnLine(geom, precision, srs = Config.mapConfiguration.projection) {
    // SD Utilisation de turf. Besoin de convertir la geom en EPSG:4326 pour que les calculs soient correct.
    // De ce fait, cette fonction est approximative ! A utiliser en conséquence.

    let convertedGeom = GeomToolbox.transform(geom, srs, 'EPSG:4326')
    let lineLength = turf.length(convertedGeom, {units: 'meters'})
    let nbSample = Math.floor(lineLength / precision)
    let toReturn = []

    for (let i = 0; i <= nbSample; i++) {
      let point = turf.along(convertedGeom, (i * precision), {units: 'meters'}).geometry
      toReturn.push(GeomToolbox.transform(point, 'EPSG:4326', srs))
    }

    return toReturn
  }

  static transform(geom, from, to) {
    let toReturn = GeoJSON.readGeometry(geom).transform(from, to)
    toReturn = JSON.parse(GeoJSON.writeGeometry(toReturn))

    return toReturn
  }

  static getLength(geom, srs = Config.mapConfiguration.projection) {
    let convertedGeom = GeomToolbox.transform(geom, srs, 'EPSG:4326')
    return turf.length(convertedGeom, {units: 'meters'})
  }
}