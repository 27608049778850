<i18n>
{
  "fr": {
    "title": "Thématiques disponibles",
    "close": "Fermer"
  },
  "de": {

  }
}
</i18n>

<template>
  <md-dialog class="themeOverlay" :md-active="show" v-on:md-clicked-outside="onClose">
    <md-dialog-title>{{ $t('title') }}</md-dialog-title>

    <md-content class="md-scrollbar dialog-content">
      <img src="@/assets/images/common/themeInfo.jpeg" />
    </md-content>
    <md-dialog-actions>
      <md-button class="md-primary" @click="onClose()">{{ $t('close') }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>

export default {
  name: 'ThemePopup',
  props: {
    show: {
      type: Boolean,
      default: () => { return false }
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
  .themeOverlay {
    img {
      max-height: 500px;
    }
  }
</style>
