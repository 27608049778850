<template>
  <div class="module-card clickable md-elevation-2">
    <img class="icon" :src="icon" />
    <span class="name">{{value.name}}</span>
  </div>
</template>

<script>

import eauSurface from '@/assets/icons/eauSurface_bordure.png'
import eauSout from '@/assets/icons/eauSout_bordure.png'
import eauMeteoriques from '@/assets/icons/eauMeteoriques_bordure.png'
import gestionRessource from '@/assets/icons/gestionRessource_bordure.png'

export default {
  name: 'ModuleCard',
  props: {
    value: {
      type: Object,
      default: () => { return {} }
    }
  },
  computed: {
    icon () {
      switch (this.value.icon) {
        case 'eauSurface':
          return eauSurface
        case 'eauSout':
          return eauSout
        case 'eauMeteoriques':
          return eauMeteoriques
        case 'gestionRessource':
          return gestionRessource
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss">
  .module-card {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    width: 140px;
    height: 140px;
    margin: 15px;
    padding: 10px;

    .name {
      text-align: center;
      display: block;
      font-weight: 600;
    }

    .icon {
      width: 70px
    }
  }
</style>
