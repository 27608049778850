<i18n>
{
  "fr": {
    "coord-title": "Coordonnées",
    "projection-code": "Projection utilisée",
    "EPSG:32632": "SCR UTM Nord Zone 32"
  },
  "de": {

  }
}
</i18n>

<template>
  <div class="container tool" v-if="show">
    <p><b>{{ $t('coord-title') }}:</b> {{ `${new Intl.NumberFormat('fr-CH').format(currentGeom.coordinates[0].toFixed(0))} / ${new Intl.NumberFormat('fr-CH').format(currentGeom.coordinates[1].toFixed(0))}` }}</p>
    <p><b>{{ $t('projection-code') }}</b>: <a :href="`https://epsg.io/${projectionCode.split(':')[1]}`" target="_blank">{{ $t(projectionCode) }}</a></p>
  </div>
</template>

<script>

import MapStoreMixin from '@/mixins/store/map'
import Config from '@/config'

export default {
  name: 'CurrentCoordinate',
  mixins: [
    MapStoreMixin
  ],
  computed: {
    show () {
      return this.currentGeom !== null
    },
    projectionCode () {
      return Config.mapConfiguration.projection
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
