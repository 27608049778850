<i18n>
{
  "fr": {

  },
  "de": {

  }
}
</i18n>

<template>
  <div class="tool" id="layer-info" v-if="show">
    <Pie
      :data="data"
      :layout="layout"
      :donutThickness="10"
    />
  </div>
</template>

<script>

import MapStoreMixin from '@/mixins/store/map'
import LayerStoreMixin from '@/mixins/store/layer'
import Pie from '@/components/chart/pie'

export default {
  name: 'PieChart',
  mixins: [
    LayerStoreMixin,
    MapStoreMixin
  ],
  components: {
    Pie
  },
  data () {
    return  {

    }
  },
  computed: {
    show () {
      return this.params !== null && this.currentGeom === null
    },
    params () {
      let toReturn = this.layer.webviewConfig.availableTools.find((t) => t.key === 'PIE_CHART')
      return typeof toReturn === 'undefined' ? null : toReturn.params
    },
    data () {
      if (this.params === null) return {}
      return this.params.data.fr
    },
    layout () {
      if (this.params === null) return {}
      return this.params.layout
    },
    layer () {
      return this.selectedLayers[0]
    }
  },
  methods: {
    handleError (e) {
      console.log(e)
    }
  }
}
</script>

<style lang="scss">

</style>
