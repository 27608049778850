<i18n>
{
  "fr": {
    "no-access-title": "Vous n'avez pas d'accès suffisant",
    "please-connect": "Veuillez-vous connecter pour accéder à cette partie du site.",
    "connect-label": "Se connecter"
  },
  "de": {
    "no-access-title": "Sie haben keinen ausreichenden Zugriff",
    "please-connect": "Bitte einlogge Sie sich, um auf diesen Teil der Website zuzugreifen.",
    "connect-label": "Einloggen"
  }
}
</i18n>

<template>
  <div id="no-access">
    <div class="login-form-container">
      <md-card class="no-access-form">
        <md-card-header>
          <div class="md-title">{{ $t('no-access-title') }} <md-icon class="md-accent">vpn_key</md-icon></div>
        </md-card-header>

        <md-card-content>
          {{ $t('please-connect') }}
        </md-card-content>

        <md-card-actions>
          <md-button class="md-raised md-primary" @click="$router.push({ name: 'login' })">{{ $t('connect-label') }}</md-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NoAccess'
}
</script>

<style lang="scss">
  .login-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 120px);
  }

  .no-access-form {
    max-width: 550px;
  }
</style>
