<i18n>
{
  "fr": {
    "available-sources": "Sources disponibles",
    "show": "Afficher",
    "hide": "Masquer",
    "layer-list": "Cartes affichées",
    "no-layer-title": "Pas de contenu",
    "no-layer-desc": "Ajouter des couches depuis le panneau des sources disponibles pour pouvoir les gérer ici.",
    "description-title": "Description",
    "information-title": "Information",
    "sourceType-label": "Format de la source",
    "url-label": "URL",
    "layerName-label": "Nom de la couche",
    "projection-label": "Projection",
    "type-label": "Type de géométrie",
    "timeDimensionType-label": "Type de dimension de temps",
    "precision-label": "Résolution spatiale",
    "unit-label": "Unité",
    "msm": "msm",
    "m/sol": "m/sol",
    "timeDimensionFormat-MM": "Mensuelle",
    "param-label": "Paramètre",
    "level": "Niveau",
    "depth": "Profondeur",
    "height": "Hauteur",
    "level-diff": "Différence de niveau",
    "background-map-label": "Fond topographique",
    "map-aerial": "Vue satellite",
    "map-farbe": "Swisstopo (couleur)",
    "map-grau": "Swisstopo (niveau de gris)",
    "close": "Fermer",
    "show-detail-label": "Détail",
    "add-layer-label": "Ajouter la couche",
    "available-groups": "Thématiques disponibles",
    "add-group-label": "Ajouter la carte",
    "show-group-label": "Afficher le groupe",
    "hide-group-label": "Masquer le groupe",
    "info-label": "A propos",
    "map-relief": "SwissALTI3D (Relief ombré)",
    "info-theme-label": "A propos des thématiques",
    "delete-label": "Supprimer la carte",
    "show-groupInfo-label": "Afficher les informations relative à la carte",
    "hide-groupInfo-label": "Masquer les informations relative à la carte",
    "opacity": "Opacité",
    "show-detail-group": "Afficher le contenu de la carte",
    "hide-detail-group": "Masquer le contenu de la carte",
    "count-layerGroup-label": "Nombre de carte affichées",
    "open-panel": "Ouvrir le panneau",
    "close-panel": "Fermer le panneau",
    "module-selection-label": "Sélection des modules"
  },
  "de": {

  }
}
</i18n>

<template>
  <div id="layer-list" v-show="isOpen">

    <div class="header-sidebar">
      <img v-if="$appName === 'RESERVAQUA'" src="@/assets/images/logo/projet.png" />
    </div>

    <md-content class="main-container">

      <div class="header-list-layers">
        <h3 @click="openNavList('sourceList')" class="clickable">
          {{ $t('available-groups') }}
          <md-tooltip v-show="!showSourceList">{{ $t('open-panel') }}</md-tooltip>
        </h3>
        <md-button class="md-icon-button md-dense" @click="showThemeDialog()" v-if="$appName === 'RESERVAQUA'">
          <md-icon>help</md-icon>
          <md-tooltip md-direction="bottom">{{ $t('info-theme-label') }}</md-tooltip>
        </md-button>
      </div>

      <div :class="['add-layers-container', { 'open': showSourceList }]" v-if="!layerLoading">
        <ModuleCarousel v-if="showGroupList" />
        <SourceList v-if="showGroupList" />
        <ModuleList v-if="showModuleList" />
      </div>

      <md-divider></md-divider>

      <div class="header-list-layers">
        <h3 @click="openNavList('layerList')" class="clickable">
          {{ $t('layer-list') }}
          <md-tooltip v-show="!showLayerList">{{ $t('open-panel') }}</md-tooltip>
        </h3>
        <transition name="slide-fade" mode="out-in">
          <span class="layerGroupCount" :key="layerGroups.length">
            {{ layerGroups.length }}
            <md-tooltip>{{ $t('count-layerGroup-label') }}</md-tooltip>
          </span>
        </transition>
      </div>

      <div class="background-map">
        <div class="md-caption withBorder">
          <label for="background-map" @click="expandBackgroundSelector = !expandBackgroundSelector">
            <span class="label-text">{{ $t('background-map-label') }}</span>
            <md-icon v-if="expandBackgroundSelector">arrow_drop_up</md-icon>
            <md-icon v-if="!expandBackgroundSelector">arrow_drop_down</md-icon>
          </label>
        </div>
        <div class="background-content" v-if="expandBackgroundSelector">
          <md-field>
            <md-select v-model="backgroundSelector.name" name="background-map">
              <md-option value="ch.swisstopo.pixelkarte-farbe">{{ $t('map-farbe') }}<img src="@/assets/images/mapPreview/farbe.png" /></md-option>
              <md-option value="ch.swisstopo.pixelkarte-grau">{{ $t('map-grau') }}<img src="@/assets/images/mapPreview/grau.png" /></md-option>
              <md-option value="ch.swisstopo.swissimage" default>{{ $t('map-aerial') }}<img src="@/assets/images/mapPreview/aerial.png" /></md-option>
              <md-option value="ch.swisstopo.swissalti3d-reliefschattierung">{{ $t('map-relief') }}<img src="@/assets/images/mapPreview/relief.png" /></md-option>
            </md-select>
          </md-field>
          <div class="opacity-header md-caption">
            {{$t('opacity')}}
          </div>
          <vue-slider
            v-model="backgroundSelector.opacity"
            :min="0"
            :max="1"
            :interval="0.1"
          >
          </vue-slider>
        </div>
      </div>

      <div :class="['current-layers-container', { 'open': showLayerList }]" v-if="!layerLoading">
        <LayerList />
      </div>

    </md-content>



    <div class="footer-sidebar">
      <md-button class="md-icon-button md-primary md-raised" @click="showAboutDialog()">
        <md-icon>info</md-icon>
        <md-tooltip md-direction="bottom">{{ $t('info-label') }}</md-tooltip>
      </md-button>
      <div class="project-link">
        <a :href="websiteFooter.url" target="_blank">{{ websiteFooter.name }}</a>
      </div>
    </div>

  </div>
</template>

<script>

import LayerList from '@/components/layerNavigation/layer/layerList'
import SourceList from '@/components/layerNavigation/source/sourceList'
import ModuleList from '@/components/layerNavigation/module/moduleList'
import ModuleCarousel from '@/components/layerNavigation/module/moduleCarousel'

import LayerStoreMixin from '@/mixins/store/layer'
import MapStoreMixin from '@/mixins/store/map'

import Config from '@/config.json'

export default {
  name: 'LayerNavigation',
  components: {
    SourceList,
    ModuleList,
    LayerList,
    ModuleCarousel
  },
  mixins: [
    LayerStoreMixin,
    MapStoreMixin
  ],
  props: {
    isOpen: {
      type: Boolean,
      default: () => { return true }
    }
  },
  data () {
    return {
      expandBackgroundSelector: false,
      backgroundSelector: {},
      websiteFooter: Config.websiteFooter
    }
  },
  watch: {
    'backgroundSelector.name': function (val) {
      this.$store.commit('setBackgroundLayerName', val)
    },
    'backgroundSelector.opacity': function (val) {
      this.$store.commit('setBackgroundLayerOpacity', val)
    }
  },
  mounted () {
    this.backgroundSelector = this.backgroundLayer
  },
  methods: {
    openNavList (listName) {
      switch (listName) {
        case 'sourceList':
          this.$store.commit('setShowSourceList', true)
          this.$store.commit('setShowLayerList', false)
          break
        case 'layerList':
          this.$store.commit('setShowSourceList', false)
          this.$store.commit('setShowLayerList', true)
          break
      }
    },
    showAboutDialog () {
      this.$emit('showAboutDialog')
    },
    showThemeDialog () {
      this.$emit('showThemeDialog')
    },
    handleError (e) {
      console.log(e)
    }
  }
}
</script>

<style lang="scss">
  .nav-list {
    padding: 5px;
    padding-top: 15px;
    padding-bottom: 10px;
    display: flex;
    width: 100%;
    overflow: auto;
    background: none !important;

    &.reverse-order {
      flex-direction: column-reverse;
      padding-top: 15px;

      & > .group:nth-of-type(2), .empty-layers {
        margin-bottom: auto;
      }
    }
  }
</style>

<style lang="scss" scoped>

</style>

<style lang="scss">

@import '@/styles/variable.scss';

  .module-option {
    .md-list-item-text {
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;

      .module-legend {
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
    }
  }

  #layer-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    user-select: none;

    .background-map {
      padding: 15px;
      background-color: white;
      padding-top: 5px;
      padding-bottom: 0px;

      .md-caption.withBorder {
        border-bottom: 1px solid #949494;
        margin-left: -15px;
        padding-left: 15px;
        margin-right: -15px;
        padding-right: 15px;
      }

      label {
        display: flex;
        background-color: white;
        margin-bottom: 0;
        margin-top: 0;
        padding-top: 2px;
        min-height: 30px;
        font-size: 14px;

        .label-text {
          font-size: 14px;
          color: var(--md-theme-default-text-primary-on-background-variant, rgba(0,0,0,0.87));
          margin-right: auto;
        }

        .md-icon {
          margin: 0;
        }
      }

      .md-field {
        margin: 4px 0 10px;
      }

      .background-content {
        background-color: #00000012;
        padding-left: 15px;
        margin-left: -15px;
        padding-right: 15px;
        margin-right: -15px;
        margin-top: -5px;
        padding-bottom: 15px;
      }
    }

    .md-content.main-container {
      background: none;
      width: 20vw;
      min-width: 365px;
      height: calc(100vh - 227px);
    }

    .md-divider {
      height: 3px;
    }

    .header-list-layers {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #ffffffbf;

      h3 {
        margin-right: 15px;
        margin-left: 15px;
        margin-top: 15px;
      }

      .md-button {
        margin-top: 10px;
        margin-right: 13px;
      }

      .layerGroupCount {
        margin-top: 10px;
        margin-right: 14px;
        padding: 2px;
        height: 24px;
        border-radius: 20px;
        width: 24px;
        text-align: center;
      }
    }

    .list-layers {
      padding: 5px;
      padding-top: 15px;
      padding-bottom: 10px;
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: auto;
      background: none;

      &.reverse-order {
        flex-direction: column-reverse;
        padding-top: 15px;

        .empty-layers {
          margin-bottom: auto;
        }

        & > .group:first-of-type {
          margin-bottom: auto;
        }
      }
    }

    .group {
      background-color: white;
      width: 100%;
      margin-bottom: 10px;

      cursor: pointer;

      .header-group {
        display: grid;
        grid-template-areas: 'label arrow title button';
        grid-template-columns: 5px 24px auto auto;

        .module-label {
          grid-area: label;
          width: 7px;
        }

        .expand-arrow {
          grid-area: arrow;

          .md-icon {
            margin-top: 3px;
            margin-left: 5px;
            margin-right: -15px;
          }
        }

        .group-name {
          grid-area: title;
          margin-top: 4px;
          padding-left: 10px;

          .md-title {
            font-size: 14px;
            line-height: 16px;
          }

          .md-subtitle {
            color: #a7a7a7;
            font-size: 14px;
            margin-top: -3px;
            user-select: none;
          }
        }

        .button-container {
          grid-area: button;
          margin-left: auto;
        }
      }

      .layer-container {
        display: flex;
        flex-direction: column-reverse;
        padding: 5px;
        border: 2px solid white;
        border-top: none;
        background-color: #00000021;
      }

      .group-source {
        &.inUse {
          opacity: 0.2;
          box-shadow: none;
          background-color: #ededed;
        }
      }

    }

    .add-layers-container {
      display: none;

      &.open {
        display: flex;
        flex-direction: column;
      }

      .layer-container {
        flex-direction: column;
      }
    }

    .current-layers-container {

      display: none;

      &.open {
        display: block;
      }

      .draggable-wrapper {
        display: flex;
        flex-direction: column-reverse;
      }

    }

    .empty-layers {
      user-select: none;
      margin-bottom: 30px;

      &.md-empty-state-leave-active {
        display: none !important;
      }

      .md-icon {
        width: 80px;
        min-width: 80px;
        font-size: 80px !important;
      }
      .md-empty-state-label {
        font-size: 16.38px;
      }
    }

    .header-sidebar {
      display: flex;
      padding: 15px;
      background: white;
      justify-content: center;

      img {
        max-width: 300px;
      }
    }

    .footer-sidebar {
      display: flex;
      width: 100%;
      height: 100px;
      margin-top: 1px;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;

      .project-link {
        margin-top: 15px;
        background-color: $titleColor;
        width: 100%;
        text-align: center;
        padding: 7px;
        a {
          color: white;
        }
      }
    }

  }

  .dialog-content {
    padding-left: 25px;
    padding-right: 25px;

    .section {
      .md-caption {
        display: block;
        margin-bottom: -15px;
      }

      p {
        margin-bottom: 6px;
      }
    }
  }

  .slide-fade-enter-active {
    transition: all 0.1s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }

  .add-layers-container .list-layers {
    height: calc(100vh - 435px);
  }

  .add-layers-container .nav-list {
    height: calc(100vh - 385px);
  }

  .current-layers-container .list-layers {
    height: calc(100vh - 385px);
    max-height: calc(100vh - 385px);
  }

</style>
