import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store/index.js'

import VueI18n from 'vue-i18n'

import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import VueMaterial from 'vue-material'

import proj4 from '@/utils/proj4'
import { register as registerProj4 } from 'ol/proj/proj4'

import Toolbox from '@/utils/toolbox'

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

import Config from '@/config.json'

Vue.use(VueI18n)
Vue.use(VueMaterial)

registerProj4(proj4)

Vue.component('VueSlider', VueSlider)

Toolbox.registerVueFilters(Vue)

const i18n = new VueI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  silentTranslationWarn: true
})

Vue.config.productionTip = false
Vue.prototype.$appName = Config.appName

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
