<i18n>
  {
    "fr": {
      "return-to-module-selection": "Retour à la sélection des modules"
    }
  }
</i18n>

<template>
  <div class="module-carousel">
    <div class="item clickable" @click="updateSelectedModule(null)">
      <md-icon class="back">keyboard_arrow_left</md-icon>
      <md-tooltip>{{ $t('return-to-module-selection') }}</md-tooltip>
    </div>
    <div class="item-container">
      <div
        :class="['item', 'clickable', { 'selected': moduleData.key === selectedModule.key }]"
        v-for="moduleData in availableModules"
        :key="moduleData.key"
        @click="updateSelectedModule(moduleData)"
      >
        <img class="icon" :src="getIcon(moduleData)" />
        <md-tooltip>{{ moduleData.name }}</md-tooltip>
      </div>
    </div>
  </div>
</template>

<script>

import LayerStoreMixin from '@/mixins/store/layer'

import eauSurface from '@/assets/icons/eauSurface_bordure.png'
import eauSout from '@/assets/icons/eauSout_bordure.png'
import eauMeteoriques from '@/assets/icons/eauMeteoriques_bordure.png'
import gestionRessource from '@/assets/icons/gestionRessource_bordure.png'

export default {
  name: 'ModuleCard',
  mixins: [
    LayerStoreMixin
  ],
  methods: {
    getIcon (moduleData) {
      switch (moduleData.icon) {
        case 'eauSurface':
          return eauSurface
        case 'eauSout':
          return eauSout
        case 'eauMeteoriques':
          return eauMeteoriques
        case 'gestionRessource':
          return gestionRessource
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss">
  .module-carousel {
    display: flex;
    background-color: #ffffffbf;
    padding: 5px;

    .item-container {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
    }

    .item {
      height: 40px;
      width: 40px;
      filter: grayscale(100%);

      .back {
        margin-top: 10px;
      }

      &.selected {
        filter: none;
      }
    }
  }
</style>
