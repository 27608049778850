'use strict'
import _ from 'lodash'

export default class EPSG {
  constructor (projection) {
    this.projection = projection

    switch (projection.getCode()) {
      case 'EPSG:2056':
      case 'CH1903+':
        this.resolutions = [4000, 3750, 3500, 3250, 3000, 2750, 2500, 2250, 2000, 1750, 1500, 1250, 1000, 750, 650, 500, 250, 100, 50, 20, 10, 5, 2.5, 2, 1.5, 1, 0.5, 0.25, 0.1]
        this.extent = [2420000, 1030000, 2900000, 1350000]
        break

      case 'EPSG:32632':
        this.resolutions = [36441.426484375, 18220.7132421875, 9110.35662109375, 4555.178310546875, 2277.5891552734374, 1138.7945776367187, 569.3972888183594, 284.6986444091797, 142.34932220458984, 71.17466110229492, 35.58733055114746, 17.79366527557373, 8.896832637786865, 4.4484163188934325, 2.2242081594467162, 1.1121040797233581, 0.5560520398616791, 0.27802601993083953, 0.13901300996541976]
        this.extent = [166021.44, 0.00, 534994.66, 9329005.18]
        break

      default:
        throw new Error(`projection ${projection.getCode()} was not found`)
    }

    this.matrixIds = _.range(this.resolutions.length)
    this.projection.setExtent(this.extent)
  }
}
