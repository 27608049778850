<i18n>
{
  "fr": {

  },
  "de": {

  }
}
</i18n>

<template>
  <div id="vector-info" class="tool" v-if="show">
    <h3>{{ title }}</h3>
    <md-content class="md-scrollbar main-container">

      <div class="container data-table">
        <md-table>
          <md-table-row
            v-for="(d, i) in data"
            :key="`d_${i}`"
          >
            <md-table-cell>{{ $t(d.key) }}</md-table-cell>
            <md-table-cell>{{ d.value }}</md-table-cell>
          </md-table-row>
        </md-table>
      </div>

    </md-content>
  </div>
</template>

<script>

import MapStoreMixin from '@/mixins/store/map'
import LayerStoreMixin from '@/mixins/store/layer'

export default {
  name: 'SimpleTable',
  mixins: [
    MapStoreMixin,
    LayerStoreMixin
  ],
  data () {
    return  {

    }
  },
  computed: {
    show () {
      return this.params !== null && this.currentGeom === null
    },
    params () {
      let toReturn = this.layer.webviewConfig.availableTools.find((t) => t.key === 'SIMPLE_TABLE')
      return typeof toReturn === 'undefined' ? null : toReturn.params
    },
    data () {
      if (this.params === null) return {}
      return this.params.data.fr
    },
    title () {
      if (this.params === null) return ''
      return this.params.title.fr
    },
    layer () {
      return this.selectedLayers[0]
    }
  },
  methods: {
    handleError (e) {
      console.log(e)
    }
  }
}
</script>

<style lang="scss">

</style>
