'use strict'
import proj4 from 'proj4'

// NOTE http://blog.paloo.fr/index.php/posts/longitude-x-latitude-y-ou-pas

// CH1903 https://epsg.io/21781 (longitude=Y, latitude=X)
proj4.defs('EPSG:21781', '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.4,15.1,405.3,0,0,0,0 +units=m +no_defs')
proj4.defs('CH1903', proj4.defs('EPSG:21781'))

// CH1903+ https://epsg.io/2056 (longitude=Y, latitude=X)
proj4.defs('EPSG:2056', '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs')
proj4.defs('CH1903+', proj4.defs('EPSG:2056'))

// WGS 84 / UTM zone 32N https://epsg.io/32632
proj4.defs("EPSG:32632","+proj=utm +zone=32 +datum=WGS84 +units=m +no_defs");

export default proj4
