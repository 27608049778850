import AccessToken from '@/utils/accessToken'

export default class FetcherHelper {
  static getHeaders () {
    let headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }

    let accessToken = AccessToken.get()

    if (accessToken !== null) {
      headers['Authorization'] = 'Bearer ' + accessToken.token
    }

    return {
      headers: headers
    }
  }

  static encodeQueryData(data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
  }
}
