<template>
  <div>
    <Map
      :layers="layers"
      :projection="projection"
      :viewExtent="viewExtent"
      :center="center"
      :defaultZoom="defaultZoom"
      :minZoom="minZoom"
      :maxZoom="maxZoom"
      @init="initMap"
    />
  </div>
</template>

<script>

import LayerStoreMixin from '@/mixins/store/layer'
import MapStoreMixin from '@/mixins/store/map'
import CommonStoreMixin from '@/mixins/store/common'

import Map from '@/components/vue-map/map.vue'

import LayerGroup from 'ol/layer/Group'
import SwisstopoLayer from '@/components/vue-map/layers/swissTopo'
// import { TileDebug } from 'ol/source'
// import TileLayer from 'ol/layer/Tile'

import Config from '@/config.json'

export default {
  name: 'MapView',
  mixins: [
    LayerStoreMixin,
    MapStoreMixin,
    CommonStoreMixin
  ],
  components: {
    Map
  },
  data () {
    return {
      projection: Config.mapConfiguration.projection,
      viewExtent: Config.mapConfiguration.view.extent,
      center: Config.mapConfiguration.center,
      defaultZoom: Config.mapConfiguration.view.zoom,
      minZoom: Config.mapConfiguration.view.minZoom,
      maxZoom: Config.mapConfiguration.view.maxZoom,
      layers: []
    }
  },
  mounted () {
    let backgroundLayers = new LayerGroup({
      layers: [
        new SwisstopoLayer(this.backgroundLayer.name)
      ]
    })
    backgroundLayers.name = 'backgroundLayers'

    let userLayers = new LayerGroup()
    userLayers.name = 'userLayers'

    this.layers = [
      backgroundLayers,
      userLayers
      // new TileLayer({
      //   source: new TileDebug(),
      // })
    ]
  },
  watch: {
    layerGroups () {
      let userLayers = this.getLayerCollectionByName('userLayers').getLayers()
      console.log(userLayers)

      userLayers.clear()
      this.layerGroups.forEach((lg) => {
        userLayers.push(lg)
      })
    },
    'backgroundLayer.name': function () {
        let backgroundLayers = this.getLayerCollectionByName('backgroundLayers').getLayers()
        backgroundLayers.clear()
        backgroundLayers.push(new SwisstopoLayer(this.backgroundLayer.name))
    },
    'backgroundLayer.opacity': function (val) {
      this.getLayerCollectionByName('backgroundLayers').setOpacity(val)
    }
  },
  methods: {
    getLayerCollectionByName (name) {
      return this.mapRef.getLayers().getArray()[0].getLayers().getArray().find((l) => l.name === name)
    }
  }
}
</script>

<style lang="scss">
  #map {
    width: 100%;
    height: 100vh;
  }
</style>
