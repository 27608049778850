'use strict'

import moment from 'moment'
import decode from 'jwt-decode'
import _ from 'lodash'

class AccessToken {
  constructor (token) {
    this.token = token
    this.user = decode(token)
  }

  static get name () {
    return 'access_token'
  }

  static get () {
    let encodedToken = localStorage.getItem(AccessToken.name)
    if (!encodedToken) return null
    return new AccessToken(encodedToken)
  }

  static set (token) {
    localStorage.setItem(AccessToken.name, token)
  }

  get utcOffset () {
    return this.user.utcOffset || 0
  }

  get tosApproved () {
    return this.user.tosApproved || false
  }

  isLogged () {
    if (!this.user.exp) return false

    return moment.unix(this.user.exp).utc().isSameOrAfter(moment.utc())
  }

  hasRight (right) {
    return !!_.find(this.user.rights, { name: right })
  }

  hasAdminRights () {
    // Recherche un droit contenant 'admin'
    return !!_.filter(this.user.rights, right => _.includes(right.name, 'admin')).length
  }
}

export default AccessToken
