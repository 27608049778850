<i18n>
{
  "fr": {
    "tool-title": "Données",
    "no-data-title": "Aucune donnée",
    "distance-to-origin-label": "Distance à l'origine (m)",
    "LEVEL-label": "Niveau",
    "DEPTH-label": "Profondeur",
    "HEIGHT-label": "Hauteur",
    "show-topography-label": "Afficher la topographie",
    "hide-topography-label": "Masquer la topographie",
    "level-mode-label": "Niveau",
    "depth-mode-label": "Profondeur",
    "presentation-mode-label": "Mode de représentation",
    "resolution-label": "Précision de la coupe",
    "unit-is-different": "Unités différentes",
    "level": "Niveau",
    "depth": "Profondeur",
    "height": "Hauteur",
    "level-diff": "Différence de niveau",
    "msm": "msm",
    "m/sol": "m/sol",
    "coord-title": "Points de coordonnées",
    "min-label": "Minimum",
    "max-label": "Maximum",
    "mean-label": "Moyenne",
    "median-label": "Médiane",
    "table-title": "Données",
    "stats-title": "Statistiques",
    "date-label": "Date",
    "open-label": "Ouvrir"
  },
  "de": {

  }
}
</i18n>

<template>
  <div id="vector-info" class="tool" v-if="show">
    <h3>{{$t('tool-title')}}</h3>
    <md-content class="md-scrollbar main-container">
      <md-empty-state
        v-if="nodata"
        class="empty-data"
        md-rounded
        md-icon="graphic_eq"
        :md-label="$t('no-data-title')"
        :md-size="170"
      >
      </md-empty-state>

      <div class="container data-table"
        v-for="(ld, i) in layerData"
        :key="'layerDataTable_' + i"
        @mouseover="showHoverFeature(ld.feature)"
        @mouseleave="hideHoverFeature(ld.feature)"
      >
        <md-table>
          <md-table-row
            v-for="(d, i) in ld.data"
            :key="'ldf_' + i"
          >
            <md-table-cell>{{ $t(d.key) }}</md-table-cell>
            <md-table-cell v-if="!validURL(d.value)">{{ d.value }}</md-table-cell>
            <md-table-cell v-if="validURL(d.value)"><a target="_blank" :href="d.value">{{ $t(`${d.key}_open_label`) }}</a></md-table-cell>
          </md-table-row>
        </md-table>
      </div>

      <div class="loading" v-if="loading">
        <md-progress-spinner class="loading-animation" :md-diameter="100" :md-stroke="5" md-mode="indeterminate"></md-progress-spinner>
      </div>

    </md-content>
  </div>
</template>

<script>

import GeoJSON from '@/utils/geoJson'
import { Fill, Stroke, Style, Circle } from 'ol/style'
import { Vector as VectorSource } from 'ol/source'
import { Vector as VectorLayer } from 'ol/layer'
import { get as getProjection } from 'ol/proj'

import MapStoreMixin from '@/mixins/store/map'
import LayerStoreMixin from '@/mixins/store/layer'

import Config from '@/config.json'

const keyToHide = [
  "Shape_Length",
  "Shape_Area",
  "bbox",
  "OBJECTID"
]

export default {
  name: 'PointInfo',
  mixins: [
    MapStoreMixin,
    LayerStoreMixin
  ],
  data () {
    return  {
      loading: true,
      layerData: [],
      hoverLayer: null,
      highlightType: 'one',
      hoverStyle: null,
      selectedStyle: null,
      isHoveringFeature: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.updateTool()
    })
  },
  beforeDestroy() {
    this.removeTool()
  },
  watch: {
    currentGeom () {
      this.updateTool()
    },
    selectedLayers () {
      this.updateTool()
    }
  },
  computed: {
    nodata () {
      return !this.loading && this.layerData.length === 0
    },
    show () {
      return this.currentGeom !== null && this.selectedLayers.length > 0
    }
  },
  methods: {
    updateTool () {
      if (this.selectedLayers.length > 0 && this.currentGeom !== null) this.load()
      else this.removeTool()
    },
    removeTool () {
      if (this.hoverLayer === null) return
      this.mapRef.removeLayer(this.hoverLayer)
      this.hoverLayer = null
    },
    load () {
      this.isHoveringFeature = false
      this.loading = true

      this.initHoverLayer()
      this.compute().catch(this.handleError).finally(() => this.loading = false)
    },
    initHoverLayer () {

      if (this.hoverLayer !== null) this.mapRef.removeLayer(this.hoverLayer)

      this.hoverSource = new VectorSource({
        features: []
      })

      this.hoverStyle = new Style({
        stroke: new Stroke({
          color: 'rgba(0,187,255,1)',
          width: 3
        }),
        fill: new Fill({
          color: 'rgba(255,255,255,0.5)'
        }),
        image: new Circle({
          radius: 6,
          fill: new Fill({
            color: 'rgba(0,187,255,1)'
          }),
          stroke: new Stroke({
            color: 'white'
          }),
        })
      })

      this.selectedStyle = new Style({
        stroke: new Stroke({
          color: 'rgba(0,187,255,1)',
          width: 2
        }),
        image: new Circle({
          radius: 6,
          fill: new Fill({
            color: 'rgba(0,187,255,1)'
          }),
          stroke: new Stroke({
            color: 'white'
          }),
        })
      })

      this.hoverLayer = new VectorLayer({
        source: this.hoverSource,
        style: this.selectedStyle,
        zIndex: Infinity
      })

      this.mapRef.addLayer(this.hoverLayer)
    },
    compute () {
      return new Promise((resolve) => {
        this.layerData = []

          let toFetch = []
          let toAdd

          this.selectedLayers.map((l) => toFetch.push(l.getFeaturesFromCoordinates(this.mapRef, this.currentGeom)))

          Promise.all(toFetch).then((featuresCollection) => {
            if(featuresCollection.length === 1) this.highlightType = 'one'
            else this.highlightType = 'all'

            featuresCollection.forEach((features, index) => {
              // SD On ne traite que la première feature retournée
              if (typeof features[0] !== 'undefined') {
                toAdd = {
                  layer: this.selectedLayers[index],
                  data: [],
                  feature: GeoJSON.readFeature(features[0], {
                    dataProjection: getProjection(Config.mapConfiguration.projection),
                    featureProjection: getProjection(this.selectedLayers[index].getProjectionCode())
                  })
                }

                if (this.highlightType === 'one') this.hoverSource.addFeature(toAdd.feature)

                Object.keys(features[0].properties).forEach((k) => {
                  if (keyToHide.indexOf(k) === -1 && k.startsWith(Config.tool.vectorInfo.fieldPrefixDisplay)) {
                    toAdd.data.push({
                      key: k,
                      value: features[0].properties[k]
                    })
                  }
                })

                this.layerData.push(toAdd)
              }
            })

            resolve()
          })

      })
    },
    showHoverFeature (feature) {
      if (!this.isHoveringFeature) {
        switch (this.highlightType) {
          case 'all':
            feature.setStyle(this.hoverStyle)
            this.hoverSource.addFeature(feature)
           break
          case 'one':
            feature.setStyle(this.hoverStyle)
            break
        }
        this.isHoveringFeature = true
      }
    },
    hideHoverFeature (feature) {
      switch (this.highlightType) {
        case 'all':
          this.hoverSource.removeFeature(feature)
         break
        case 'one':
          feature.setStyle(this.selectedStyle)
          break
      }
      this.isHoveringFeature = false
    },
    validURL(str) {
      try {
        new URL(str);
      } catch (_) {
        return false;
      }

      return true;
    },
    handleError (e) {
      console.log(e)
    }
  }
}
</script>

<style lang="scss">
  #vector-info {

    .close-button {
      display: block;
      margin-left: auto;
      margin-right: 15px;
      margin-bottom: -30px;
      margin-top: 5px;
    }

    .md-content.main-container {
      overflow-y: auto;
      overflow-x: hidden;
      background: none;

      @media (max-height: 500px) {
        max-height: 100vh;
      }
    }

    .layer-name {
      font-size: 14px;
    }

    .container {
      margin-left: -10px;
      margin-right: -10px;
      background-color: white;

      h2 {
        margin-top: 5px;
      }
    }

    .plotly {
      width: 100%;
      height: 250px;
    }

    .empty-data {
      margin-top: 15px;
      margin-bottom: 15px;

      .md-icon {
        width: 80px;
        min-width: 80px;
        font-size: 80px !important;
      }
      .md-empty-state-label {
        font-size: 18px;
        line-height: 18px;
      }

      &.md-empty-state-leave-active {
        display: none !important;
      }
    }

    .loading {
      padding: 25px;

      .md-progress-spinner {
        margin-left: calc(50% - 50px);
      }
    }

    .partial-loading {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #ffffff9e;
      z-index: 10;

      .md-progress-spinner {
        margin-left: 88%;
      }
    }

    .data-table {

      .md-table-row {
        .md-table-cell:first-child {
          max-width: 190px;
          word-wrap: break-word;
        }
      }

    }
  }
</style>
