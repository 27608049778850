<i18n>
{
  "fr": {

  },
  "de": {

  }
}
</i18n>

<template>
  <div class="tool" id="layer-info" v-if="show">
    <h3>{{ layer.metadata.name }}</h3>
    <p>Description de la couche <i>{{ layer.metadata.name }}</i> {{ layer.metadata.description }}</p>
  </div>
</template>

<script>

import LayerStoreMixin from '@/mixins/store/layer'

export default {
  name: 'LayerInfo',
  mixins: [
    LayerStoreMixin
  ],
  data () {
    return  {

    }
  },
  computed: {
    show () {
      return false
    },
    layer () {
      return this.selectedLayers[0]
    }
  },
  methods: {
    handleError (e) {
      console.log(e)
    }
  }
}
</script>

<style lang="scss">

</style>
