import Toolbox from '@/utils/toolbox'
import OLayerGroup from 'ol/layer/Group'
import Layer from './layer.js'
import _ from 'lodash'

export default class LayerGroup extends OLayerGroup {
  constructor (sourceGroup, layerSource = null) {
    const layers = []

    if (layerSource !== null) layers.push(Layer.fromSource(layerSource))
    else {
      _.reverse(_.cloneDeep(sourceGroup.sources)).forEach((source) => {
        layers.push(Layer.fromSource(source))
      })
    }

    super({
      layers
    })

    this.sourceId = sourceGroup.id
    this.groupMetadata = sourceGroup.groupMetadata
    this.moduleMetadata = sourceGroup.moduleMetadata
    this.themeMetadata = sourceGroup.themeMetadata
    this.webviewConfig = sourceGroup.webviewConfig

    // Valeurs par défaut
    this.webviewConfig.selected = false
    this.webviewConfig.show = true
    this.webviewConfig.hidden = Toolbox.getOrDefault(sourceGroup.webviewConfig, 'hidden', false)

    return this
  }

  get layers () {
    return this.getLayers()
  }

  getLayer (sourceId) {
    return this.layers.find((l) => l.sourceId === sourceId)
  }

  addLayer (layerSource) {
    // Si le layer existe déjà dans le groupe, on ne fait rien
    if (typeof this.getLayer(layerSource.id) !== 'undefined') return
    this.layers.push(Layer.fromSource(layerSource))
  }

  removeLayer (layerSource) {
    const toRemove = this.getLayer(layerSource.id)
    this.layers.remove(toRemove)
  }
}
