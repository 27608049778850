<template>
  <div class="pie-chart" ref="chart">
    <h3>{{ title }}</h3>
    <div class="chart-container">
      <svg
        :width="width"
        :height="height"
      >
        <g class="slices" :transform="transform">
          <path
            v-for="path in slices"
            :key="path.d"
            :d="path.d"
            :fill="path.color"
            :stroke="layout.stroke.color"
            :stroke-width="layout.stroke.width"
            class="slice"
          />
          <text
            v-for="slice in slices"
            :key="`t_${slice.d}`"
            :transform="slice.centroid"
          >
            {{slice.value}}
          </text>
        </g>
      </svg>
      <div class="legends">
        <div
          class="legend"
          v-for="legend in slices"
          :key="`l_${legend.d}`"
        >
          <div class="icon" :style="{backgroundColor: legend.color, borderColor: layout.stroke.color}" />
          <span>{{ legend.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import * as d3 from 'd3'

export default {
  name: 'Pie',
  props: {
    layout: {
      type: Object,
      default: () => { return {} }
    },
    data: {
      type: Object,
      default: () => { return {} }
    },
    margin: {
      type: Number,
      default: 25
    },
    width: {
      type: Number,
      default: 260
    },
    height: {
      type: Number,
      default: 260
    }
  },
  computed: {
    transform () {
      return `translate(${this.width / 2}, ${this.height / 2})`
    },
    outerRadius () {
      return Math.min(this.width, this.height) / 2 - this.margin
    },
    innerRadius () {
      return typeof this.layout.donutThickness === 'undefined' ? 0 : this.radius - this.layout.donutThickness
    },
    labelRadius () {
      return this.outerRadius * 0.5
    },
    pieData () {
      return this.pie(Object.entries(this.data))
    },
    slices () {
      let toReturn = []

      this.pieData.forEach((sliceData) => {
        let slice = this.arc
          .startAngle(sliceData.startAngle)
          .endAngle(sliceData.endAngle)

        let label = this.arcLabel
          .startAngle(sliceData.startAngle)
          .endAngle(sliceData.endAngle)

        let centroid = label.centroid()

        toReturn.push({
          color: this.colorScale(sliceData.data[0]),
          d: slice(),
          centroid: `translate(${centroid})`,
          label: sliceData.data[0],
          value: sliceData.value
        })
      })

      return toReturn
    },
    title () {
      return this.layout.title.fr
    }
  },
  created () {
    this.colorScale = d3
      .scaleOrdinal(this.layout.colors)
      .domain(Object.entries(this.data).map(d => d[0]))

    this.pie = d3.pie().value(function(d) { return d[1] })
    this.arc = d3.arc().innerRadius(this.innerRadius).outerRadius(this.outerRadius)
    this.arcLabel = d3.arc().innerRadius(this.labelRadius).outerRadius(this.labelRadius)
  },
  data () {
    return  {
      colorScale: null
    }
  },
  methods: {
    handleError (e) {
      console.log(e)
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .legends {
    font-size: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .icon {
      width: 10px;
      height: 10px;
      border-width: 1px;
      border-style: solid;
      display: inline-block;
      margin-right: 10px;
    }
  }
</style>
