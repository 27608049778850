<i18n>
{
  "fr": {
    "error-detail-title": "Détail de l'erreur",
    "detail-button-label": "Détail",
    "error-snackbar-message": "Une erreur est survenue"
  },
  "de": {

  }
}
</i18n>

<template>

  <div id="snackbar">
    <md-snackbar md-position="left" :md-active.sync="snackbar.show" md-persistent>
      <span>{{ snackbar.message }}</span>
      <md-button class="md-primary" @click="dialog.show = true">{{ $t('detail-button-label') }}</md-button>
    </md-snackbar>

    <md-dialog-alert
      :md-active.sync="dialog.show"
      :md-title="dialog.title"
      :md-content="dialog.content"
    />
  </div>

</template>

<script>
export default {
  name: 'Snackbar',
  props: {
    title: {
      type: String
    }
  },
  event: 'change',
  data () {
    return {
      snackbar: {
        show: false,
        message: '',
        showDetailButton: false
      },
      dialog: {
        show: false,
        title: '',
        content: ''
      }
    }
  },
  methods: {
    show (message) {
      this.snackbar.showDetailButton = false
      this.snackbar.message = message
      this.snackbar.show = true
    },
    showError (e) {
      this.dialog.title = this.$t('error-detail-title')
      this.dialog.content = e

      this.snackbar.showDetailButton = true
      this.snackbar.message = this.$t('error-snackbar-message')
      this.snackbar.show = true
    }
  }
}
</script>
