import WmtsLayer from './wmtsLayer.js'
import WmsLayer from './wmsLayer.js'

export default class Layer {
  static fromSource (source) {
    switch (source.serviceType) {
      case WmtsLayer.serviceType:
        return new WmtsLayer(source)
      case WmsLayer.serviceType:
        return new WmsLayer(source)
      default:
        throw new Error(`${source.serviceType} is not supported yet.`)
    }
  }
}
