<i18n>
{
  "fr": {
    "hide-menu-label": "Masquer le menu",
    "show-menu-label": "Afficher le menu",
    "close": "Fermer",
    "zoomIn": "Zoomer",
    "zoomOut": "Dézommer",
    "centerToExtent": "Rencentrer sur la zone d'intérêt"
  },
  "de": {

  }
}
</i18n>

<template>
  <div id="main-container">
    <router-view name="splashScreen" v-if="loading"/>

    <div v-if="!loading" id="app">
      <div :class="isLeftPanelOpen ? 'sidebar left open' : 'sidebar left'">
        <LayerNavigation
          :isOpen="isLeftPanelOpen"
          v-on:showAboutDialog="onpenAboutPopup"
          v-on:showThemeDialog="onpenThemePopup"
        />
        <div class="tool-container">
          <div class="tool-button" @click="toggleLeftPanel">
            <md-tooltip md-direction="right">{{ $t(isLeftPanelOpen ? 'hide-menu-label' : 'show-menu-label') }}</md-tooltip>
            <md-icon>reorder</md-icon>
          </div>

          <div class="tool-button dense" @click="zoomIn">
            <md-tooltip md-direction="right">{{ $t('zoomIn') }}</md-tooltip>
            <md-icon>add</md-icon>
          </div>
          <div class="tool-button dense" @click="centerToExtent">
            <md-tooltip md-direction="right">{{ $t('centerToExtent') }}</md-tooltip>
            <md-icon>center_focus_weak</md-icon>
          </div>
          <div class="tool-button" @click="zoomOut">
            <md-tooltip md-direction="right">{{ $t('zoomOut') }}</md-tooltip>
            <md-icon>remove</md-icon>
          </div>

          <InteractionPanel />
        </div>
      </div>
      <div class="content">
        <router-view :class="['content-view', {'left-panel-open': isLeftPanelOpen}]"/>
        <div :class="['bottom-view', {'open': isBottomPanelOpen}, {'left-panel-open': isLeftPanelOpen}]" name="bottomView">
        </div>
      </div>
      <div :class="isRightPanelOpen ? 'sidebar right open' : 'sidebar right'">
        <ToolPanel name="sidebar-right"/>
      </div>
    </div>

    <AboutPopup :show="showAboutPopup" v-on:close="closeAboutPopup" />
    <ThemePopup :show="showThemePopup" v-on:close="closeThemePopup" />

    <Snackbar ref="snackbar" />
  </div>
</template>

<script>

import Snackbar from '@/components/common/snackbar'
import AboutPopup from '@/components/common/about'
import ThemePopup from '@/components/common/themeOverlay'

import ToolPanel from '@/components/tool/toolPanel'
import InteractionPanel from '@/components/interaction/interactionPanel'
import LayerNavigation from '@/views/map/layerNavigation.vue'

import MapInteractionMixin from '@/mixins/map/interaction'
import CommonStoreMixin from '@/mixins/store/common'
import LayerStoreMixin from '@/mixins/store/layer'
import ToolStoreMixin from '@/mixins/store/tool'
import MapStoreMixin from '@/mixins/store/map'

import Config from '@/config.json'

export default {
  name: 'App',
  mixins: [
    LayerStoreMixin,
    ToolStoreMixin,
    MapStoreMixin,
    CommonStoreMixin,
    MapInteractionMixin
  ],
  components: {
    Snackbar,
    AboutPopup,
    LayerNavigation,
    ToolPanel,
    InteractionPanel,
    ThemePopup
  },
  data () {
    return {
      loading: true
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$store.dispatch('fetchAllSources').then(() => {
        setTimeout(() => {
          this.loading = false
        }, 1000)
      }).catch(this.handleError)
    })
  },
  watch: {
    loading (state) {
      if (!state) setTimeout(() => { this.onLoaded() }, 1000) // SD Hack: Debounce du pauvre
    },
    selectedLayers (layers) {
      if (layers.length === 0) {
        this.emptyTools()
        this.setCurrentGeom(null)
      }
      else layers.forEach((layer) => this.setToolsForLayer(layer))
    }
  },
  methods: {
    onLoaded () {
      if (localStorage.getItem(Config.version) === null) {
        localStorage.setItem(Config.version, true)
        this.onpenAboutPopup()
      }
    },
    onpenAboutPopup (){
      this.setShowAboutPopup(true)
    },
    closeAboutPopup () {
      this.setShowAboutPopup(false)
    },
    onpenThemePopup (){
      this.setShowThemePopup(true)
    },
    closeThemePopup () {
      this.setShowThemePopup(false)
    }
  }
}
</script>

<style lang="scss">

@import '@/styles/variable.scss';

// General layout

@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans'), url(./fonts/OpenSans/OpenSans-Regular.ttf) format("truetype");
}

#main-container {
  font-family: 'OpenSans', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#app {
  display: flex;
  overflow:hidden;
}

// Views

.content {
  display: flex;
  flex-direction: column;

  width: 100%;

  height: 100vh;

  @media (max-height: 500px) {
    height: 100vh;
  }

  .content-view {
    height: 100%;
  }
}

.tool-container {
  display: flex;
  flex-direction: column;

  .tool-button {
    width: 34px;
    height: 34px;
    padding: 5px;
    cursor: pointer;
    margin-bottom: 5px;
    background-color: #ffffff;

    &.dense {
      margin-bottom: 0px;
      border-bottom: 1px solid grey;
    }
  }

}

.sidebar {
  display: flex;
  width: 0px;
  height: 100vh;
  position: fixed;
  z-index: 1;
  background-color: #ffffffed;
  // backdrop-filter: blur(10px);

  @media (max-height: 500px) {
    height: 100vh;
  }

  &.right {
    right: 0px;
  }

  &.open {
    display: flex;
    width: 20vw;
    min-width: 365px;

    &.right {
      width: 20vw;
    }

    .side-view {
      display: block;
    }

    .side-panel-control.right {
      left: 20vw;
    }
    .side-panel-control.left {
      right: 20vw;
    }

  }

  .side-view {
    height: 100%;
    display: none;
  }
}

.bottom-view {
  display: none;
  background-color: #ffffff9c;
  backdrop-filter: blur(10px);
  &.open {
    display: block;
    height: 275px;
    bottom: 0px;
    position: fixed;
    width: 100vw;

    &.left-panel-open {
      @media(min-width: 900px) {
        width: calc(100vw - 365px);
        left: 365px;
      }
    }
  }
}

// General rules

::selection {
  color: white !important;
  background: #8e8e8ea6 !important;
}

.clickable {
  cursor: pointer;
}

.md-list-item-text {
  font-size: 14px;
}

.md-menu.md-select input {
  font-size: 14px !important;
}

.md-caption, .md-title {
  user-select: none;
}

.geom-type-icon {
  display: inline-block;
}

.dialog-content {
  padding-left: 25px;
  padding-right: 25px;
  max-height: 500px;
  overflow: auto;

  .section {
    .md-caption {
      display: block;
      margin-bottom: -15px;
    }

    p {
      margin-bottom: 6px;
    }
  }
}

h3 {
  color: $titleColor;
}

</style>
