<i18n>
  {
    "fr": {
      "no-layer-title": "Pas de contenu"
    }
  }
</i18n>

<template>
  <md-content class="list-layers groups md-scrollbar reverse-order">
    <LayerGroup
      v-for="layerGroup in layerGroups"
      :key="`lg_${layerGroup.sourceId}`"
      :layerGroup="layerGroup"
    />

    <md-empty-state
      class="empty-layers"
      v-show="layerGroups.length === 0"
      md-rounded
      md-icon="explore"
      :md-label="$t('no-layer-title')"
      :md-size="200"
    >
    </md-empty-state>

  </md-content>
</template>

<script>

import LayerStoreMixin from '@/mixins/store/layer'

import LayerGroup from '@/components/layerNavigation/layer/layerGroup'

export default {
  name: 'LayerList',
  components: {
    LayerGroup
  },
  mixins: [
    LayerStoreMixin
  ],
  data () {
    return  {

    }
  },
  computed: {

  },
  methods: {
    handleError (e) {
      console.log(e)
    }
  }
}
</script>

<style lang="scss">

</style>
