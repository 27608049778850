<template>
  <md-content class="list-layers groups md-scrollbar">
    <SourceGroup
      v-for="sourceGroup in filtredSourceGroups"
      :sourceGroup="sourceGroup"
      :key="sourceGroup.id"
    />

    <md-empty-state
      class="empty-layers"
      v-show="filtredSourceGroups.length === 0"
      md-rounded
      md-icon="explore"
      :md-label="$t('no-layer-title')"
      :md-size="200"
    >
    </md-empty-state>
  </md-content>
</template>

<script>

import LayerStoreMixin from '@/mixins/store/layer'

import SourceGroup from '@/components/layerNavigation/source/sourceGroup'

export default {
  name: 'SourceList',
  components: {
    SourceGroup
  },
  mixins: [
    LayerStoreMixin
  ],
  data () {
    return  {

    }
  },
  computed: {

  },
  methods: {
    handleError (e) {
      console.log(e)
    }
  }
}
</script>

<style lang="scss">

</style>
