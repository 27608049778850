<i18n>
{
  "fr": {
    "legend-title": "Légende"
  },
  "de": {

  }
}
</i18n>

<template>
  <div class="tool" id="layer-info" v-if="show">
    <h3>{{$t('legend-title')}}</h3>
    <img class="legend" :src="legendUrl" />
  </div>
</template>

<script>

import LayerStoreMixin from '@/mixins/store/layer'

import Config from '@/config.json'

export default {
  name: 'LegendInfo',
  mixins: [
    LayerStoreMixin
  ],
  data () {
    return  {

    }
  },
  computed: {
    show () {
      return true
    },
    layer () {
      return this.selectedLayers[0]
    },
    legendUrl () {
      return `${Config.legendBaseUrl}/${this.$i18n.locale}/${this.layer.sourceId}.png`
    }
  },
  methods: {
    handleError (e) {
      console.log(e)
    }
  }
}
</script>

<style lang="scss" scoped>
  .legend {
    width: 332px;
    margin-left: 5px;
  }
</style>
