<i18n>
{
  "fr": {
    "portal-title": "Système d'information transfrontalier"
  },
  "de": {

  }
}
</i18n>

<template>
  <div id="loading-overlay">
    <md-card class="loading-title">
      <md-card-area class="md-elevation-4">
        <md-card-media>
          <img class="project-logo" src="@/assets/images/logo/projet.png">
        </md-card-media>
        <md-card-header>
          <span class="md-title">{{ $t('portal-title') }}</span>
        </md-card-header>
        <md-progress-bar md-mode="indeterminate"></md-progress-bar>
      </md-card-area>
    </md-card>

    <div class="logo-footer">
      <img src="@/assets/images/logo/arpaPiemonte.png" />
      <img src="@/assets/images/logo/arpaVda.png" />
      <img src="@/assets/images/logo/crealp.png" />
      <img src="@/assets/images/logo/fms.png" />
      <img src="@/assets/images/logo/iar.png" />
      <img src="@/assets/images/logo/politorino.png" />
      <img src="@/assets/images/logo/rava.png" />
      <img src="@/assets/images/logo/sca.png" />
      <img src="@/assets/images/logo/sen.png" />
    </div>

  </div>
</template>

<script>

import Config from '@/config.json'

export default {
  name: 'SplashScreen',
  data () {
    return {
      logoFooter: Config.splashScreen.logoFooter
    }
  }
}
</script>

<style lang="scss" scoped>
#loading-overlay {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: #ffffffc4;

  .loading-title {
    width: 20vw;
    min-width: 300px;
    margin: 0 auto;
    margin-top: calc(50vh - 150px)
  }

  .md-card {
    box-shadow: unset;

    .project-logo {
      padding: 22px;
      padding-bottom: 0px;
    }

    .md-title {
      font-size: 19px;
    }
  }

  .md-card-header {
    padding: 22px;
    padding-top: 14px;
    padding-bottom: 10px;
  }

  .logo-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0;
    left: 5vw;
    width: 90vw;

    img {
      height: 70px;
      margin-bottom: 20px;
      margin-right: 25px;
    }
  }
}
</style>
