<i18n>
{
  "fr": {
    "EPSG:2056": "CH 1903+",
    "EPSG:32632": "SCR UTM Nord Zone 32",
    "no-coordinate": "Aucune coordonnée"
  },
  "de": {

  }
}
</i18n>

<template>
  <div>
    <div id="map" ref="map"></div>
    <div :class="['bottomMapTool']">
      <div id="mouse-coordinate">
        <a :href="`https://epsg.io/${this.currentProj.projection.getCode().split(':')[1]}`" target="_blank">{{ $t(this.currentProj.projection.getCode()) }}</a> :
        <span id="mouse-position"></span>
      </div>
      <div id="scale-line"></div>
    </div>
    <div id="vector-click-overlay"></div>
  </div>
</template>

<script>

import 'ol/ol.css'
import Map from 'ol/Map'
import View from 'ol/View'
import { get as getProj } from 'ol/proj'
import EPSG from '@/utils/epsg'
import MousePosition from 'ol/control/MousePosition'
import { format } from 'ol/coordinate'
import ScaleLine from 'ol/control/ScaleLine'

import LayerGroup from 'ol/layer/Group'

export default {
  name: 'Map',
  props: {
    layers: {
      type: Array,
      default: () => { return [] }
    },
    projection: {
      type: String,
      default: () => { return 'EPSG:2056' }
    },
    viewExtent: {
      type: Array,
      default: () => { return [2420000, 1030000, 2900000, 1350000] }
    },
    center: {
      type: Array,
      default: () => { return [2600000, 1114000] }
    },
    defaultZoom: {
      type: Number,
      default: 18
    },
    minZoom: {
      type: Number,
      default: 15
    },
    maxZoom: {
      type: Number,
      default: 27
    },
    interactions: {
      type: Array,
      default: () => { return [] }
    }
  },
  watch: {
    layers () {
      this.updateLayers()
    }
  },
  data () {
    return {
      map: null,
      currentProj: new EPSG(getProj(this.projection))
    }
  },
  mounted () {
    this.initOpenlayers()
  },
  beforeDestroy () {
    this.interactions.forEach((i) => i.release())
  },
  methods: {
    initOpenlayers () {
      const currentLayers = new LayerGroup()
      currentLayers.name = 'currentLayers'

      this.map = new Map({
        layers: [
          currentLayers
        ],
        target: 'map',
        controls: this.initControls(),
        view: new View({
          projection: this.currentProj.projection,
          extent: this.viewExtent,
          resolutions: this.currentProj.resolutions,
          center: this.center,
          constrainOnlyCenter: true,
          smoothExtentConstraint: false,
          smoothResolutionConstraint: false,
          enableRotation: false,
          zoom: this.defaultZoom,
          minZoom: this.minZoom,
          maxZoom: this.maxZoom
        })
      })

      this.updateLayers()

      this.$emit('init', this.map)
    },
    getLayerCollectionByName (name) {
      return this.map.getLayers().getArray().find((l) => l.name === name)
    },
    initControls () {
      return [
        new MousePosition({
          coordinateFormat: (coordinate) => {
            return format(coordinate, '{x} / {y}', 0)
          },
          projection: this.projection,
          target: document.getElementById('mouse-position'),
          undefinedHTML: this.$t('no-coordinate')
        }),
        new ScaleLine({
          units: 'metric',
          target: document.getElementById('scale-line')
        })
      ]
    },
    updateLayers () {
      const currentLayers = this.getLayerCollectionByName('currentLayers').getLayers()
      currentLayers.clear()
      this.layers.forEach((l) => {
        currentLayers.push(l)
      })
    }
  }
}
</script>

<style lang="scss">
  .bottomMapTool {
    left: 10px;
  }

  #mouse-coordinate {
    display: flex;
    background-color: #ffffffcc;
    padding: 4px;
    font-size: 12px;
    border-radius: 3px;
    font-size: 12px !important;
    height: 27px;

    #mouse-position {
      margin-left: 3px;

      .ol-mouse-position {
        top: unset;
        right: unset;
        position: unset
      }
    }
  }

  #scale-line {
    height: 12px;
    position: relative;
    left: 184px;

    .ol-scale-line {
      background-color: #ffffffcc;
      .ol-scale-line-inner {
        color: unset;
        border-color: #2c3e50;
      }
    }
  }
</style>
