<i18n>
  {
    "fr": {
      "no-module-title": "Pas de contenu"
    }
  }
</i18n>

<template>
  <md-content class="module-list nav-list md-scrollbar">
    <ModuleCard
      v-for="moduleData in availableModules"
      :key="moduleData.color"
      :value="moduleData"
      @click.native="updateSelectedModule(moduleData)"
    />

    <md-empty-state
      class="empty-layers"
      v-show="availableModules.length === 0"
      md-rounded
      md-icon="explore"
      :md-label="$t('no-module-title')"
      :md-size="200"
    >
    </md-empty-state>
  </md-content>
</template>

<script>

import ModuleCard from '@/components/layerNavigation/module/moduleCard.vue'
import LayerStoreMixin from '@/mixins/store/layer'

export default {
  name: 'LayerInfo',
  components: {
    ModuleCard
  },
  mixins: [
    LayerStoreMixin
  ],
  data () {
    return  {

    }
  },
  methods: {
    handleError (e) {
      console.log(e)
    }
  }
}
</script>

<style lang="scss" scoped>
  .module-list {
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: flex-start;
  }
</style>
