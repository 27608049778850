'use strict'

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState({
      isLeftPanelOpen: state => state.common.isLeftPanelOpen,
      isRightPanelOpen: state => state.common.isRightPanelOpen,
      isBottomPanelOpen: state => state.common.isBottomPanelOpen,
      showAboutPopup: state => state.common.showAboutPopup,
      showThemePopup: state => state.common.showThemePopup
    }),
    ...mapGetters([

    ])
  },
  methods: {
    ...mapMutations([
      'setLeftPanelState',
      'setRightPanelState',
      'toggleLeftPanel',
      'setShowAboutPopup',
      'setShowThemePopup'
    ]),
    ...mapActions([

    ]),
  }
}
