'use strict'
import Vue from 'vue'
import _ from 'lodash'
import SourceProxy from '@/proxies/source.js'
import LayerGroup from '@/components/vue-map/layers/layerGroup'

import Config from '@/config.json'

// NOTE
// Les states avec un _ doivent être accédés via des getters
// Les mutations avec un _ doivent être appelées uniquement depuis des actions

export default {
  state: {
    layerLoading: true,
    sourceGroups: [],     // Groupe de source de données disponible à l'utilisation dans le viewer
    layerGroups: [],      // Groupe de layer actuellement utilisé dans le viewer
    selectedLayers: [],   // Liste de layers sélectionné par l'utilisateur
    layerGroupInfo: null, // Groupe de layer actuellement sélectionné pour afficher ses informations (bouton i sur les groupes de layers)
    selectedModule: null, // Module sélectionné par l'utilisateur
    showSourceList: true, // Affiche ou non la liste des sources de données disponible dans le panneau de navigation
    showLayerList: false  // Affiche ou non la liste des layers actuellement utilisé dans le panneau de navigation
  },
  getters: {
    // Retourne les groupes de sources disponible à la sélection
    availableSourceGroups (state) {
      // On enlève ceux qui sont caché à la sélection
      let toReturn = state.sourceGroups.filter((sourceGroup) => {
        return typeof sourceGroup.webviewConfig.hidden === 'undefined' || !sourceGroup.webviewConfig.hidden
      })

      // On enlève les groupes de sources qui sont déjà ajouté en tant que groupe de layer
      toReturn = toReturn.filter((s) => {
        let groupInUse = state.layerGroups.find((lg) => { return lg.sourceId === s.id })
        return typeof groupInUse === 'undefined'
      })

      return toReturn
    },
    // Retourne la groupes de sources filtrés à afficher dans le panneau de navigation
    filtredSourceGroups (state, getters) {
      if (state.selectedModule === null) return this.availableSourceGroups

      return getters.availableSourceGroups.filter((sourceGroup) => {
        return sourceGroup.moduleMetadata.color === state.selectedModule.color
      })
    },
    // Retourne les modules disponiles à la sélection
    availableModules (state, getters) {
      let toReturn = []

      getters.availableSourceGroups.forEach((sourceGroup) => {
        // HACK la couleur est utilisé comme ID...
        let found = toReturn.find((module) => module.key === sourceGroup.moduleMetadata.color)
        if (typeof found === 'undefined') toReturn.push({
          key: sourceGroup.moduleMetadata.color,
          ... sourceGroup.moduleMetadata
        })
      })

      return _.orderBy(toReturn, ['ord'], ['asc'])
    },
    // Retourne tous les layers actuellement utilisé dans le viewer
    currentLayers (state) {
      let toReturn = []
      _.reverse(state.layerGroups).map((g) => toReturn = toReturn.concat(g.layers))
      return _.reverse(toReturn)
    },
    // Afficher ou non la liste des groupes de sources dans le panneau de navigation
    showGroupList (state) {
      return state.selectedModule !== null
    },
    // Afficher ou non la liste des modules dans le panneau de navigation
    showModuleList (state) {
      return state.selectedModule === null
    }
  },
  mutations: {
    updateSelectedModule (state, module) {
      state.selectedModule = module
    },
    updateSourceGroups (state, sourceGroups) {
      Vue.set(state, 'sourceGroups', sourceGroups);
    },
    addOrUpdateSourceGroup (state, sourceGroup) {
      let groupIndex = state.sourceGroups.indexOf(sourceGroup)
      if (groupIndex === -1) state.sourceGroups.push(sourceGroup)
      else Vue.set(state.sourceGroups, groupIndex, sourceGroup)
    },
    addOrUpdateLayerGroup (state, layerGroup) {
      let groupIndex = state.layerGroups.indexOf(layerGroup)
      if (groupIndex === -1) state.layerGroups.push(layerGroup)
      else Vue.set(state.layerGroups, groupIndex, layerGroup)
    },
    removeLayerGroup (state, layerGroup) {
      // On enlève les layers de ce groupe qui seraient sélectionné
      layerGroup.layers.forEach((layer) => {
        let toRemove = state.selectedLayers.find((l) => l.id === layer.id)
        if (typeof toRemove !== 'undefined') state.selectedLayers.splice(state.selectedLayers.indexOf(toRemove), 1)
      })

      // Supprimer le groupe de cartes si
      if (state.layerGroupInfo === layerGroup) state.layerGroupInfo = null

      // Et on supprime le groupe de layer
      let groupIndex = state.layerGroups.indexOf(layerGroup)
      state.layerGroups.splice(groupIndex, 1)
    },
    removeSelectedLayer (state, layer) {
      let toRemove = state.selectedLayers.find((l) => l.id === layer.id)
      if (typeof toRemove !== 'undefined') state.selectedLayers.splice(state.selectedLayers.indexOf(toRemove), 1)
    },
    // Sélectionne ou désélectionne un layer
    toggleSelectedLayer (state, layer)  {
      // Multiple sélection
      if (Config.featureConfiguration.layerSelectionType === 'multi') {
        let selectedLayer = state.selectedLayers.find((l) => l.id === layer.id)

        if (typeof selectedLayer === 'undefined') {
          layer.webviewConfig.selected = true
          state.selectedLayers.push(layer)
        } else {
          layer.webviewConfig.selected = false
          state.selectedLayers.splice(state.selectedLayers.indexOf(selectedLayer), 1)
        }
      } else {
        // Sélection simple
        let currentLayer = state.selectedLayers[0]

        if(state.selectedLayers.length > 0) {
          state.selectedLayers[0].webviewConfig.selected = false
          state.selectedLayers = []
        }
        if (currentLayer !== layer) {
          layer.webviewConfig.selected = true
          state.selectedLayers.push(layer)
        }
      }
    },
    setLayerGroupInfo (state, layerGroup) {
      if (state.layerGroupInfo !== null && state.layerGroupInfo !== layerGroup) {
        state.layerGroupInfo.webviewConfig.showGroupInfo = false
      }
      state.layerGroupInfo = layerGroup
      state.layerGroupInfo.webviewConfig.showGroupInfo = true
    },
    removeLayerGroupInfo (state) {
      if (state.layerGroupInfo !== null) state.layerGroupInfo.webviewConfig.showGroupInfo = false
      state.layerGroupInfo = null
    },
    _setLoadingState (state, loading) {
      state.layerLoading = loading
    },
    setShowSourceList (state, show) {
      state.showSourceList = show
    },
    setShowLayerList (state, show) {
      state.showLayerList = show
    }
  },
  actions: {
    // Récupération des groupes de sources de données via l'API
    fetchAllSources ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('_setLoadingState', true)

        SourceProxy.getGroups().then((sourceGroups) => {
          commit('updateSourceGroups', sourceGroups)
          commit('_setLoadingState', false)
          resolve()
        }).catch(reject)
      })
    },
    // Ajouter un groupe de source à la liste des groupes de layer
    addSourceGroup ({ state, commit, getters }, sourceGroup) {
      // Si le groupe existe déjà, on ne fait rien
      let existingGroup = state.layerGroups.find((g) => {return g.sourceId === sourceGroup.id})
      if (typeof existingGroup !== 'undefined') return

      let toAdd = new LayerGroup(sourceGroup)

      // On ajoute le groupe de layer crée à partir de la source
      // et on met à jour l'affichage des différentes listes dans le panneau de navigation
      commit('setLayerGroupInfo', toAdd)
      commit('addOrUpdateLayerGroup', toAdd)
      commit('setShowSourceList', false)
      commit('setShowLayerList', true)

      // S'il n'y a plus de groupes de sources à afficher, on le retire des modules sélectionnés
      if (getters.filtredSourceGroups.length === 0) {
        commit('updateSelectedModule', null)
      }
    },
    removeLayerGroup ({ commit }, layerGroup) {
      commit('removeLayerGroup', layerGroup)
      layerGroup.layers.forEach((layer) => {
        commit('removeSelectedLayer', layer)
      })
    }
  }
}
