<i18n>
{
  "fr": {
    "dataSource-title": "Aucune source | Source de donnée | Source de données"
  },
  "de": {

  }
}
</i18n>

<template>
  <div class="container tool" v-if="show">
    <h3>{{$tc('dataSource-title', dataSources.length)}}</h3>
    <ul>
      <li v-for="(dataSource, i) in dataSources" :key="i">{{ dataSource }}</li>
    </ul>
  </div>
</template>

<script>
import _ from 'lodash'

import MapStoreMixin from '@/mixins/store/map'
import LayerStoreMixin from '@/mixins/store/layer'

export default {
  name: 'dataSource',
  mixins: [
    LayerStoreMixin,
    MapStoreMixin
  ],
  computed: {
    dataSources () {
      let toReturn = []
      let layers = []

      if (this.layerGroupInfo === null) return []

      // Si on est au niveau du group de couche, on met en commun
      // toutes les sources de données des couches du group layerGroupInfo.
      // Sinon, on met en commun toutes les sources de données des layers sélectionnés
      if (this.selectedLayers.length === 0) {
        layers = this.layerGroupInfo.layers.getArray()
      } else {
        layers = this.selectedLayers
      }

      layers.forEach((layer) => {
        toReturn = _.union(toReturn, layer.metadata.dataSources)
      })

      return toReturn
    },
    dataSourcesDisplay () {
      return this.dataSources.join(', ')
    },
    show () {
      return this.layerGroupInfo !== null && this.dataSources.length > 0 && this.currentGeom === null
    }
  }
}

</script>

<style lang="scss" scoped>
  ul {
    padding-left: 0px;
    font-size: 12px;
  }

  li {
    list-style-type: none;
  }
</style>
