<i18n>
{
  "fr": {
    "vectorInfo-label": "Information (i)"
  },
  "de": {

  }
}
</i18n>


<template>
  <div :class="['tool-button', state]" @click="toggleState">
    <Keypress key-event="keyup" :key-code="73" @success="toggleState" />
    <md-icon class="vector-info-icon">info</md-icon>
    <md-tooltip md-direction="right">{{ $t('vectorInfo-label') }}</md-tooltip>
  </div>
</template>

<script>

import InteractionMixin from '@/mixins/map/interaction'
import LayerStoreMixin from '@/mixins/store/layer'

export default {
  name: 'Point',
  mixins: [
    InteractionMixin,
    LayerStoreMixin
  ],
  data () {
    return {
      state: 'disable'
    }
  },
  watch: {
    state (val) {
      if (val === 'focus') return this.init()
      if (val === 'disable') return this.clear()
    }
  },
  methods: {
    init () {
      if(this.drawInteraction !== null) {
        this.drawInteraction.source.clear()
        this.enableDrawInteraction()
      } else {
        this.createDrawInteraction({
          type: 'Pointer',
          drawInteractionFeature: false,
          onDrawEnd: this.onDrawEnd
        })
      }
      document.body.style.cursor = "help"
    },
    clear () {
      this.removeDrawInteraction()
      document.body.style.cursor = "default"
    },
    toggleState (){
      if (this.state === 'disable') return this.state = 'focus'
      if (this.state === 'focus') return this.state = 'disable'
      if (this.state === 'enable') return this.state = 'focus'
    },
    onDrawEnd () {
      this.state = 'enable'
    }
  }
}
</script>

<style lang="scss">
.tool-button {

  .vector-info-icon {
    font-size: 23px !important;
  }

  &.focus {
    background-color: #184aa0 !important;
    .md-icon {
      color: white !important;
    }
  }

  &.enable {
    background-color: #4d86e8 !important;
    .md-icon {
      color: white !important;
    }
  }

}
</style>
