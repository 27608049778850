<i18n>
  {
    "fr": {
      "add-group-label": "Ajouter la carte",
      "show-detail-group": "Afficher le contenu de la carte",
      "hide-detail-group": "Masquer le contenu de la carte",
      "delete-label": "Supprimer la carte",
      "show-group-label": "Afficher le groupe",
      "hide-group-label": "Masquer le groupe"
    }
  }
</i18n>

<template>
  <div class="group md-elevation-2">
    <div class="header-group layer-group">
      <div class="module-label" :style="{background: layerGroup.moduleMetadata.color}"></div>
      <div class="expand-arrow" @click="toggleExpand()">
        <md-icon v-if="open">keyboard_arrow_up</md-icon>
        <md-icon v-if="!open">keyboard_arrow_down</md-icon>
        <md-tooltip>{{ $t(open ? 'hide-detail-group' : 'show-detail-group') }}</md-tooltip>
      </div>
      <div class="group-name" @click="toggleExpand()">
        <div class="md-subtitle">
          {{layerGroup.themeMetadata.name}}
        </div>
        <div class="md-title">
          {{ layerGroup.groupMetadata.name }}
        </div>
      </div>
      <div class="button-container">
        <md-button :class="['md-icon-button', {'md-primary': show}]" @click="toggleGroup()">
          <md-icon>remove_red_eye</md-icon>
          <md-tooltip md-direction="bottom">{{ $t(show ? 'hide-group-label' : 'show-group-label') }}</md-tooltip>
        </md-button>
        <md-button class="md-icon-button" @click="removeLayerGroup(layerGroup)">
          <md-icon>delete_outline</md-icon>
          <md-tooltip md-direction="bottom">{{ $t('delete-label') }}</md-tooltip>
        </md-button>
      </div>
    </div>
    <div v-show="open" class="layer-container" @click="setFocus()">
      <LayerCard
        v-for="currentLayer in layerGroup.layers.getArray()"
        :key="currentLayer.sourceId"
        :class="['group-source', {selected: selectedLayers.includes(currentLayer)}]"
        :layer="currentLayer"
        :layerGroup="layerGroup"
        :disabled="!show"
      >
      </LayerCard>
    </div>
  </div>
</template>

<script>

import LayerStoreMixin from '@/mixins/store/layer'
import LayerCard from '@/components/layerNavigation/layer/layerCard'

export default {
  name: 'LayerGroup',
  components: {
    LayerCard
  },
  mixins: [
    LayerStoreMixin
  ],
  props: {
    layerGroup: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return {
      manualOpen: false,
      manualOpenState: false
    }
  },
  computed: {
    isSelected () {
      return this.layerGroupInfo === this.layerGroup
    },
    show () {
      return this.isSelected
    },
    open () {
      return this.manualOpen ? this.manualOpenState : this.isSelected
    }
  },
  watch: {
    show () {
      this.manualOpen = false
      this.manualOpenState = false
      this.layerGroup.webviewConfig.show = this.show
      this.layerGroup.setVisible(this.show)
      this.addOrUpdateLayerGroup(this.layerGroup)
    }
  },
  methods: {
    toggleExpand () {
      this.manualOpen = true
      this.manualOpenState = !this.manualOpenState
    },
    toggleGroup () {
      if (this.isSelected) this.removeLayerGroupInfo()
      else {
        this.setLayerGroupInfo(this.layerGroup)

        // Si un layer déjà sélectionné n'est pas dans le même layer group,
        // alors il faut l'enlever de la sélection
        if (!this.layerGroup.getLayers().getArray().includes(this.selectedLayers[0])) this.toggleSelectedLayer(this.selectedLayers[0])
      }
    },
    setFocus () {
      if (!this.isSelected) this.toggleGroup()
    },
    handleError (e) {
      console.log(e)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>