'use strict'

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState({
      tools: state => state.tool.tools
    }),
    ...mapGetters([
      'toolButtons'
    ])
  },
  methods: {
    ...mapMutations([
      'setToolsForLayer',
      'emptyTools'
    ]),
    ...mapActions([

    ]),
  }
}
