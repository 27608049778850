import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'
import GeoJSON from 'ol/format/GeoJSON'
import { Style, Fill, Stroke } from 'ol/style'

export default class MaskLayer extends VectorLayer {
  constructor (geoJson, opacity) {
    super({
      source: new VectorSource({
        features: new GeoJSON().readFeatures(geoJson)
      }),
      style: new Style({
        fill: new Fill({
          color: 'black'
        }),
        stroke: new Stroke({
          color: 'white',
          width: 6
        })
      }),
      opacity: opacity
    })

    this.on('postcompose', function (e) {
      e.context.globalCompositeOperation = 'source-over'
    })

    this.on('precompose', function (e) {
      e.context.globalCompositeOperation = 'destination-in'
    })

    return this
  }
}
