'use strict'

import { Draw as DrawInteraction, DoubleClickZoom } from 'ol/interaction'
import { Fill, Stroke, Style, Circle } from 'ol/style'
import { Vector as VectorSource } from 'ol/source'
import { Vector as VectorLayer } from 'ol/layer'
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import GeoJSON from '@/utils/geoJson'
import availableInteractions from '@/components/interaction/availableInteractions.json'
import Config from '@/config.json'

import _ from 'lodash'

import MapStoreMixin from '@/mixins/store/map'
import LayerStoreMixin from '@/mixins/store/layer'

let getDefaultInteractionConfiguration = (key) => {
  return availableInteractions.find((ic) => ic.key === key)
}

const ZOOM_INCREMENT = 1

export default {
  mixins: [
    MapStoreMixin,
    LayerStoreMixin
  ],
  components: {
    Keypress: () => import('vue-keypress')
  },
  computed: {
    interactionButtons () {
      let toReturn = []
      let found
      this.selectedLayers.forEach((layer) => {
        layer.webviewConfig.availableInteractions.forEach((interaction) => {
          found = toReturn.find((i) => i.key === interaction.key)
          if (typeof found === 'undefined') toReturn.push(getDefaultInteractionConfiguration(interaction.key))
        })
      })

      return toReturn
    }
  },
  methods: {
    createDrawInteraction ({
      type,
      clickTolerance = 6,
      snapTolerance = 12,
      drawInteractionFeature = true,
      style = null,
      onDrawEnd = (e) => { console.log('onDrawEnd', e) }
    } = {}) {
      let features = []
      let source = new VectorSource({
        features: features
      })

      if (style === null) {
        style = new Style({
          stroke: new Stroke({
            color: 'black',
            width: 3
          }),
          fill: new Fill({
            color: 'rgba(255,255,255,0.5)'
          })
        })
      }

      let layer = new VectorLayer({
        source: source,
        style: style,
        zIndex: Infinity
      })

      // Gestion de types d'interaction custom
      let interactionStyle = undefined
      let geomType = type

      if (type === 'Pointer') {
        interactionStyle = new Style({
          image: new Circle({
            radius: 0
          })
        })
        geomType = 'Point'
      }

      let interaction = new DrawInteraction({
        type: geomType,
        source: source,
        clickTolerance: clickTolerance,
        snapTolerance: snapTolerance,
        style: interactionStyle
      })

      interaction.on('drawend', (e) => {
        let geom = e.feature.getGeometry()

        let beginPoint
        let endPoint

        if (drawInteractionFeature) {
          switch (geomType) {
            case 'LineString':
              beginPoint = new Feature(new Point(_.head(geom.getCoordinates())))
              beginPoint.setStyle(new Style({
                image: new Circle({
                  radius: 6,
                  fill: new Fill({
                    color: 'green'
                  }),
                  stroke: new Stroke({
                    color: 'white'
                  }),
                })
              }))
              source.addFeature(beginPoint)

              endPoint = new Feature(new Point(_.last(geom.getCoordinates())))
              endPoint.setStyle(new Style({
                image: new Circle({
                  radius: 6,
                  fill: new Fill({
                    color: 'red'
                  }),
                  stroke: new Stroke({
                    color: 'white'
                  })
                })
              }))
              source.addFeature(endPoint)
              break
            case 'Point':
              beginPoint = new Feature(new Point(geom.getCoordinates()))
              beginPoint.setStyle(new Style({
                image: new Circle({
                  radius: 6,
                  fill: new Fill({
                    color: 'red'
                  }),
                  stroke: new Stroke({
                    color: 'white'
                  }),
                })
              }))
              source.addFeature(beginPoint)
              this.centerTo(geom.getCoordinates())
              break
          }
        }

        let toReturn = JSON.parse(GeoJSON.writeGeometry(geom))
        this.setCurrentGeom(toReturn)
        onDrawEnd(toReturn)
      })

      this.setDrawInteraction({
        interaction,
        source,
        layer
      })
      this.addDrawInteraction()
    },
    addDrawInteraction () {
      this.mapRef.addLayer(this.drawInteraction.layer)
      this.enableDrawInteraction()
    },
    removeDrawInteraction () {
      this.disableDrawInteraction()
      this.mapRef.removeLayer(this.drawInteraction.layer)
    },
    enableDrawInteraction () {
      this.mapRef.addInteraction(this.drawInteraction.interaction)
    },
    disableDrawInteraction () {
      this.mapRef.removeInteraction(this.drawInteraction.interaction)
    },
    enableDoubleClick () {
      let dblClickInteraction = this.mapRef.$map.getInteractions().getArray().find((i) => { return i instanceof DoubleClickZoom })
      if (typeof dblClickInteraction !== 'undefined') this.mapRef.$map.addInteraction(new DoubleClickZoom())
    },
    disableDoubleClick () {
      let dblClickInteraction = this.mapRef.$map.getInteractions().getArray().find((i) => { return i instanceof DoubleClickZoom })
      this.mapRef.$map.removeInteraction(dblClickInteraction)
    },
    zoomIn () {
      this.mapView.animate({
        zoom: this.mapView.getZoom() + ZOOM_INCREMENT,
        duration: 500
      })
    },
    zoomOut () {
      this.mapView.animate({
        zoom: this.mapView.getZoom() - ZOOM_INCREMENT,
        duration: 500
      })
    },
    centerToExtent () {
      this.centerTo(Config.mapConfiguration.center)
    },
    centerTo (coordinates) {
      this.mapView.animate({
        center: coordinates,
        zoom: Config.mapConfiguration.view.zoom,
        duration: 1000
      })
    }
  }
}
