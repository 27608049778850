'use strict'

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState({
      mapLoading: state => state.map.mapLoading,
      hasTopographyLayer: state => state.map.hasTopographyLayer,
      topographyLayer: state => state.map.topographyLayer,
      backgroundLayer: state => state.map.backgroundLayer,
      mapLegend: state => state.map.mapLegend,
      mapRef: state => state.map.mapRef,
      drawInteraction: state => state.map.drawInteraction,
      currentGeom: state => state.map.currentGeom
    }),
    ...mapGetters([
      'mapView'
    ])
  },
  methods: {
    ...mapMutations([
      'setCurrentGeom',
      'setDrawInteraction',
      'setBackgroundLayerName',
      'setBackgroundLayerOpacity'
    ]),
    ...mapActions([
      'initMap'
    ]),
  }
}
