<i18n>
{
  "fr": {
    "layerGroupInfo-title": "Information sur la carte"
  },
  "de": {

  }
}
</i18n>

<template>
  <div class="container tool" v-if="show">
    <h3>{{layerGroupInfo.groupMetadata.name}}</h3>
    <p class="desc" v-html="layerGroupInfo.groupMetadata.description"></p>
  </div>
</template>

<script>

import LayerStoreMixin from '@/mixins/store/layer'

export default {
  name: 'layerGroupDescription',
  mixins: [
    LayerStoreMixin
  ],
  computed: {
    show () {
      return this.layerGroupInfo !== null
    }
  }
}

</script>

<style lang="scss" scoped>
  .desc {
    text-align: justify;
  }
</style>
