<template>
  <md-content class="md-scrollbar main-container tool-panel">
    <ModuleDescription :show="shwoModuleDescription" />
    <div v-if="showTool">
      <LayerGroupHeaderDescription :show="showLayerGroupHeader" :showDescription="showLayerGroupHeaderDesc"/>
      <LayerGroupDescription v-if="this.tools.length === 0"/>
      <component v-for="tool in tools" :key="tool.vueKey" :is="tool.componentName"></component>
      <DataSource />
    </div>
  </md-content>
</template>

<script>

import CommonStoreMixin from '@/mixins/store/common'
import ToolStoreMixin from '@/mixins/store/tool'
import LayerStoreMixin from '@/mixins/store/layer'

import LayerInfo from '@/components/tool/layerInfo'
import VectorInfo from '@/components/tool/vectorInfo'
import LegendInfo from '@/components/tool/legendInfo'
import PieChart from '@/components/tool/pieChart'
import CurrentCoordinate from '@/components/tool/currentCoordinate'
import LayerGroupDescription from '@/components/tool/layerGroupDescription'
import LayerGroupHeaderDescription from '@/components/tool/layerGroupHeaderDescription'
import DataSource from '@/components/tool/dataSource'
import SimpleTable from '@/components/tool/simpleTable'
import ModuleDescription from '@/components/tool/moduleDescription'

export default {
  name: 'ToolPanel',
  mixins: [
    ToolStoreMixin,
    CommonStoreMixin,
    LayerStoreMixin
  ],
  components: {
    LayerInfo,
    VectorInfo,
    LegendInfo,
    CurrentCoordinate,
    LayerGroupDescription,
    LayerGroupHeaderDescription,
    DataSource,
    PieChart,
    SimpleTable,
    ModuleDescription
  },
  watch: {
    tools () {
      this.setPanelState()
    },
    layerGroupInfo () {
      this.setPanelState()
    },
    selectedModule () {
      this.setPanelState()
    },
    showSourceList () {
      this.setPanelState()
    }
  },
  computed: {
    showLayerGroupHeader () {
      return this.tools.length > 0 || this.layerGroupInfo !== null
    },
    shwoModuleDescription () {
      return this.selectedModule !== null && this.showSourceList
    },
    showLayerGroupHeaderDesc () {
      return this.selectedLayers.length === 0
    },
    showTool () {
      return !this.showSourceList
    }
  },
  methods: {
    setPanelState () {
      if (this.layerGroupInfo !== null && !this.showSourceList) this.setRightPanelState(true)
      else if (this.tools.length > 0) this.setRightPanelState(true)
      else if (this.shwoModuleDescription) this.setRightPanelState(true)
      else this.setRightPanelState(false)
    }
  }
}

</script>

<style lang="scss">
  .tool-panel {
    .tool {
      background-color: white;
      padding: 10px;
      margin-top: 15px;
      border: 1px solid #d4d4d4;

      h3 {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .tool-panel {
    width: 100%;
    padding: 15px;
    padding-top: 0px;
    overflow: auto;
    background: none;
    background-color: unset !important;
  }
</style>
